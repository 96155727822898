import { Checkbox, Collapse, Drawer, List } from "antd";
import styles from "./dosificationDrawer.module.scss";
import closeIcon from "../../../assets/icons/others/close-icon.svg";
import downarrow from "../../../assets/icons/downArrow.svg";
import uparrow from "../../../assets/icons/uparrow.svg";
import arrowback from "../../../assets/icons/arrow_back.svg";
import { useEffect, useState } from "react";
import {
  NotificationPositive,
  notificationNegative,
} from "../../Shared/Notifacation/NotificationLuca";
import axios from "axios";
import { ActivityIndicator } from "../../ActivityIndicator/ActivityIndicator";
import useIsMobile from "../../../hooks/useIsMobile";
interface PropsFilter {
  onClose: () => void;
  visible: boolean;
}
const { Panel } = Collapse;

const dosificationCategories = [
  "https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/Ingl%C3%A9s_+Scope+%26+Sequence+-+Level+1.pdf",
  "https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/Ingl%C3%A9s_+Scope+%26+Sequence+-+Level+2.pdf",
  "https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/Ingl%C3%A9s_+Scope+%26+Sequence+-+Level+3.pdf",
  "https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/Ingl%C3%A9s_+Scope+%26+Sequence+-+Level+4.pdf",
  "https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/Ingl%C3%A9s_+Scope+%26+Sequence+-+Level+5.pdf",
  "https://general-storage-prd.s3.us-west-2.amazonaws.com/luca2-backend/pdf/Ingl%C3%A9s_+Scope+%26+Sequence+-+Level+6.pdf",
];

const grados = [
  "1st Grade",
  "2nd Grade",
  "3rd Grade",
  "4th Grade",
  "5th Grade",
  "6th Grade",
];

const DosificationDrawer = ({ onClose, visible }: PropsFilter) => {
  const isMobile = useIsMobile();
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const isPanelActive = (panelKey: string) => activePanels.includes(panelKey);

  const [pdfUrlToDownload, setPdfUrlToDownload] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (i: number) => {
    setActivePanels([i.toString()]);
    setPdfUrlToDownload(dosificationCategories[i]);
  };

  useEffect(() => {}, [activePanels]);

  const handleSubmit = async () => {
    if (activePanels && activePanels.length > 0) {
      setIsLoading(true);
      try {
        const pdfUrl = pdfUrlToDownload;

        const partesDeUrl = pdfUrl.split("/");
        const nombreDelArchivo = partesDeUrl[partesDeUrl.length - 1];

        const response = await axios.get(pdfUrl, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "application/pdf" });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = nombreDelArchivo;
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(blobUrl);
        NotificationPositive(
          "¡Bien! Se ha descargado el archivo correctamente."
        );
      } catch (error) {
        console.error(error);
        notificationNegative(
          "¡Ups! Algo salió mal. Por favor, inténtalo de nuevo."
        );
      }
      setIsLoading(false);
    } else {
      notificationNegative("¡Ups! No se ha seleccionado un grado.");
    }
  };

  return (
    <Drawer
      height={isMobile ? "400px" : ""}
      placement={isMobile ? "bottom" : "right"}
      width={500}
      onClose={onClose}
      open={visible}
      closable={false}
      style={{
        background: "white !important",
      }}
      title={
        <div className={styles.header}>
          <div className={styles.headerContainer}>
            <div>
              <img src={arrowback} alt="arrow back" onClick={onClose} />
            </div>
            <div className={styles.titleHeader}>
              <h1>Back to lessons</h1>
            </div>
            <img src={closeIcon} alt="close icon" onClick={onClose} />
          </div>
          <div className={styles.descriptionContainer}>
            Download Luca's 24/25 English dosages.
          </div>
        </div>
      }
      footer={
        <div className={styles.footer}>
          <div className={styles.taskButton} onClick={handleSubmit}>
            Download Scope & Sequence
          </div>
        </div>
      }
    >
      {isLoading ? (
        <ActivityIndicator></ActivityIndicator>
      ) : (
        <>
          {grados.map((grado, i) => {
            return (
              <div className={styles.cardContainer}>
                <div
                  className={
                    isPanelActive(i.toString())
                      ? styles.clickedPanelHeader
                      : styles.panelHeader
                  }
                >
                  <Checkbox
                    checked={isPanelActive(i.toString())}
                    onChange={() => {
                      handleCheckboxChange(i);
                    }}
                    value={activePanels}
                    style={{ marginLeft: 30, marginRight: 10 }}
                  />{" "}
                  {grado}
                </div>
              </div>
            );
          })}
        </>
      )}
    </Drawer>
  );
};

export default DosificationDrawer;
