import thumbnailClaseEnglish from "../../../../assets/images/thumbnail/teacherGuide.png";
import thumbnailClase from "../../../../assets/images/thumbnail/thumbnailOrientacionDidactica.png";
import thumbnailCiencias from "../../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../../assets/images/thumbnail/thumbnailEspañol.png";
import thumbnailDynamics from "../../../../assets/images/thumbnail/thumbnailDynamics.png";
import thumbnailDiapositivasEnglish from "../../../../assets/images/thumbnail/slidesEnglish.png";
import thumbnailDiapositivas from "../../../../assets/images/thumbnail/thumbnailDiapositivas.png";
import ensayoMatematica from "../../../../assets/images/thumbnail/ensayoMatematica.png";
import ensayoEspañol from "../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../assets/images/thumbnail/ensayoCiencias.png";
import writingExamSpain from "../../../../assets/images/thumbnail/coverExamSpain.png";
import thumbnailBookPagesEnglish from "../../../../assets/images/thumbnail/bookEnglish.png";
import thumbnailBookPages from "../../../../assets/images/thumbnail/thumbnailBookPages.png";
import listeningExam from "../../../../assets/images/thumbnail/listening_exam.png";
import writingExam from "../../../../assets/images/thumbnail/writing_exam.png";
import CoverWriting from "../../../../assets/images/thumbnail/CoverWriting.png";
import CoverEvaluation from "../../../../assets/images/thumbnail/CoverEvaluation.png";
import { getLessonsByCourse_getLessonsByCourse } from "../../../../__generated__/gql-types/getLessonsByCourse";

export const getLabelByLessonTypeEnglish = (lessonType: string) => {
  switch (lessonType) {
    case "videolesson":
      return "Video Lesson";
    case "classroomguide":
      return "Learning Plan";
    case "evaluation":
      return "Test";
    case "redaction":
      return "Essay";
    case "slides":
      return "Slides";
    case "book_pages":
      return "Book";
    case "literacy":
      return "Reading";
    case "audio":
      return "Listening";
    case "conversation":
      return "Conversation";
    case "audio_exam":
      return "Listening Exam";
    case "writing_exam":
      return "Writing Exam";
    default:
      return "Video Lesson";
  }
};

export const getLabelByLessonTypeSpanish = (lessonType: string) => {
  switch (lessonType) {
    case "videolesson":
      return "Video Lección";
    case "classroomguide":
    case "manipulative_dynamics":
      return "Orientación didáctica";
    case "evaluation":
      return "Evaluación";
    case "redaction":
      return "Redacción";
    case "slides":
      return "Diapositivas";
    case "book_pages":
      return "Libro";
    case "conversation":
      return "Conversación";
    case "audio_exam":
      return "Examen de Audio";
    case "writing_exam":
      return "Examen";
    default:
      return "Video Lección";
  }
};

export const functionReturnImg = (
  lesson: getLessonsByCourse_getLessonsByCourse
) => {
  if (lesson.lesson_type === "classroomguide") {
    if (lesson.subject?.id === "16") {
      return thumbnailClaseEnglish;
    } else {
      return thumbnailClase;
    }
  }
  if (lesson.lesson_type === "manipulative_dynamics") {
    return thumbnailDynamics;
  }

  if (lesson.lesson_type === "slides") {
    if (lesson.subject?.id === "16") {
      return thumbnailDiapositivasEnglish;
    } else {
      return thumbnailDiapositivas;
    }
  }
  if (lesson.lesson_type === "book_pages") {
    if (lesson.subject?.id === "16") {
      return thumbnailBookPagesEnglish;
    } else {
      return thumbnailBookPages;
    }
  }
  if (lesson.lesson_type === "evaluation") {
    if (lesson.subject?.id === "1") {
      return thumbnailMate;
    }
    if (lesson.subject?.id === "2" || lesson.subject?.id === "17") {
      return thumbnailEspañol;
    }
    if (lesson.subject?.id === "4") {
      return thumbnailCiencias;
    }
    if (lesson.subject?.id === "16") {
      return CoverEvaluation;
    }
  }
  if (lesson.lesson_type === "redaction") {
    if (lesson.subject?.id === "1") {
      return ensayoMatematica;
    }
    if (lesson.subject?.id === "2" || lesson.subject?.id === "17") {
      return ensayoEspañol;
    }
    if (lesson.subject?.id === "4") {
      return ensayoCiencias;
    }
    if (lesson.subject?.id === "16") {
      return CoverWriting;
    }
  }
  if (lesson.lesson_type === "writing_exam") {
    if (lesson.subject?.id === "2") {
      return writingExamSpain;
    }
    if (lesson.subject?.id === "16") {
      return writingExam;
    } else {
      return writingExamSpain;
    }
  }

  if (lesson.lesson_type === "audio_exam") {
    return listeningExam;
  }
};
