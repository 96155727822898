import { Drawer } from "antd";
import styles from "./filterDrawerMobile.module.scss";
import closeIcon from "../../../../../../assets/icons/others/close-icon.svg";
import { TaskActiveFilters } from "../../../../../SingleClass/Filters/TaskActiveFilters";
import { StudentCalificationFilters } from "../../../../../SingleClass/Filters/StudentActiveFilters";
import { RecommendedContentActiveFilters } from "../../../../../SingleClass/Filters/RecommendedContentActiveFilters";

interface PropsFilter {
  sortedInfo?: {};
  sortedInfoStudent?: {};
  filterActive: boolean;
  selectedStatusTask?: string;
  selectedStatusStudent?: string;
  setSortedInfo?: (e: {}) => void;
  setSortedInfoStudent?: (e: {}) => void;
  setFilterActive: (e: boolean) => void;
  setSelectedStatusTask?: (e: string) => void;

  type: string;
  setSepBookPage?: (value: string) => void;
  setPageNumber?: (e: number) => void;
  sepBookPage?: string;
  sepBookPagesList?: string[];
  weekList?: string[];
  selectedWeek?: string;
  setSelectedWeek?: (value: string) => void;
}
const FilterDrawerMobile = ({
  type,
  filterActive,
  setFilterActive,
  setSelectedStatusTask,
  selectedStatusTask,
  setSortedInfo,
  sortedInfo,

  selectedStatusStudent,
  setSortedInfoStudent,
  setSepBookPage,
  setPageNumber,
  sepBookPage,
  sepBookPagesList,
  weekList,
  selectedWeek,
  setSelectedWeek,
}: PropsFilter) => {
  return (
    <Drawer
      placement={"bottom"}
      width={500}
      onClose={() => setFilterActive(false)}
      visible={filterActive}
      height={"500px"}
      closable={false}
      style={{
        background: "white !important",
      }}
      title={
        <div className={styles.header}>
          <div className={styles.titleHeader}>
            <h1>Filtros</h1>
          </div>

          <img
            src={closeIcon}
            alt="close icon"
            onClick={() => {
              if (
                type === "homework" &&
                setSelectedStatusTask &&
                setSortedInfo
              ) {
                setSortedInfo({
                  order: "descend",
                  columnKey: "start_date",
                });
                setSelectedStatusTask("finished");
              }

              if (type === "student" && setSortedInfoStudent) {
                setSortedInfoStudent({
                  order: "descend",
                  columnKey: "start_date",
                });
              }
              setFilterActive(false);
            }}
          />
        </div>
      }
      footer={
        <div className={styles.footer}>
          <div
            className={styles.alumnoButton}
            onClick={() => {
              if (
                type === "homework" &&
                setSelectedStatusTask &&
                setSortedInfo
              ) {
                setSortedInfo({
                  order: "descend",
                  columnKey: "start_date",
                });
                setSelectedStatusTask("finished");
              }
              if (type === "recommended" && setSelectedWeek && setSepBookPage) {
                setSelectedWeek("");
                setSepBookPage("");
              }
              setFilterActive(false);
            }}
          >
            Restaurar Filtros
          </div>
          <div
            className={styles.taskButton}
            onClick={() => setFilterActive(false)}
          >
            Aplicar filtros
          </div>
        </div>
      }
    >
      {type === "homework" && (
        <TaskActiveFilters
          filterActive={filterActive}
          selectedStatusTask={selectedStatusTask}
          setSelectedStatusTask={setSelectedStatusTask}
          setSortedInfo={setSortedInfo}
          sortedInfo={sortedInfo}
        />
      )}
      {type === "student" && (
        <StudentCalificationFilters
          selectedStatusHomework={selectedStatusStudent}
        />
      )}
      {type === "recommended" && (
        <RecommendedContentActiveFilters
          filterActive={filterActive}
          setSepBookPage={setSepBookPage}
          setPageNumber={setPageNumber}
          sepBookPage={sepBookPage}
          sepBookPagesList={sepBookPagesList}
          weekList={weekList}
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
        />
      )}
    </Drawer>
  );
};

export default FilterDrawerMobile;
