import { FC } from "react";
import styles from "./cardDosification.module.scss";
import defaultImage from "../../../../assets/images/defaultImage.svg";
import videoLight from "../../../../assets/icons/Video_light.svg";
import evaluationIcon from "../../../../assets/icons/evaluationIcon.svg";
import guionIcon from "../../../../assets/icons/guionIcon.svg";
import pizza from "../../../../assets/dataMock/Pizza_portada.png";

import conversationIcon from "../../../../assets/icons/conversation.svg";
import { getLessonsByCourse_getLessonsByCourse } from "../../../../__generated__/gql-types/getLessonsByCourse";
import {
  functionReturnImg,
  getLabelByLessonTypeEnglish,
  getLabelByLessonTypeSpanish,
} from "./utils";

interface CardProps {
  setLessonId?: (id: string) => void;
  showDrawer: () => void;
  lesson: getLessonsByCourse_getLessonsByCourse;
  index: number;
  setIndividualLesson?: (lesson: any) => void;
}

const CardDosification: FC<CardProps> = ({
  lesson,
  showDrawer,
  setLessonId,
  setIndividualLesson,
}) => {
  return (
    <div
      className={styles.cardContainer}
      onClick={() => {
        setIndividualLesson && setIndividualLesson(lesson);
        setLessonId && setLessonId(lesson?.id);
        showDrawer();
      }}
    >
      <div className={styles.videoQuizContainer}>
        <img
          src={
            lesson.lesson_type === "videolesson"
              ? videoLight
              : lesson.lesson_type === "classroomguide" ||
                lesson.lesson_type === "redaction" ||
                lesson.lesson_type === "manipulative_dynamics"
              ? guionIcon
              : lesson.lesson_type === "evaluation"
              ? evaluationIcon
              : lesson.lesson_type === "audio_exam"
              ? evaluationIcon
              : lesson.lesson_type === "writing_exam"
              ? evaluationIcon
              : lesson.lesson_type === "conversation"
              ? conversationIcon
              : videoLight
          }
          style={{ width: "100%" }}
        />

        {lesson.subject?.id === "16"
          ? lesson.lesson_type &&
            getLabelByLessonTypeEnglish(lesson.lesson_type)
          : lesson.lesson_type &&
            getLabelByLessonTypeSpanish(lesson.lesson_type)}
      </div>
      <img
        src={
          Number(lesson?.id) === 1812
            ? pizza
            : functionReturnImg(lesson)
            ? functionReturnImg(lesson)
            : lesson.lesson_content?.videos?.length &&
              lesson.lesson_content?.videos[0]?.imageUrl
            ? lesson.lesson_content?.videos[0].imageUrl
            : lesson.lesson_content?.aiconversations &&
              lesson.lesson_content?.aiconversations.card_image
            ? lesson.lesson_content?.aiconversations?.card_image
            : lesson?.lesson_type === "literacy" &&
              lesson?.lesson_content?.literacy?.cover
            ? lesson?.lesson_content?.literacy?.cover
            : defaultImage
        }
        style={{ width: "100%" }}
      />

      <div className={styles.cardContent}>
        <div className={styles.titleCard}>{lesson?.title}</div>

        <div>
          <h3>{lesson?.sep_name}</h3>

          <div className={styles.containerPages}>
            <div className={styles.pages}>
              {(lesson?.lesson_type === "videolesson" ||
                lesson?.lesson_type === "classroomguide" ||
                lesson?.lesson_type === "manipulative_dynamics" ||
                lesson?.lesson_type === "slides") &&
                "Didáctica"}
              {(lesson?.lesson_type === "book_pages" ||
                lesson?.lesson_type === "evaluation" ||
                lesson?.lesson_type === "audio_exam" ||
                lesson?.lesson_type === "writing_exam" ||
                lesson?.lesson_type === "redaction" ||
                lesson?.lesson_type === "literacy" ||
                lesson?.lesson_type === "conversation") &&
                "Práctica"}
            </div>
            <div className={styles.trim}>
              <div className={styles.bubble}>{lesson?.grade_id}°</div>
              <div className={styles.bubble}>Semana {lesson?.week}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDosification;
