import React, { FC } from "react";
import { HomeworkCard } from "./StudentCard";
import styles from "./studentsTable.module.scss";

interface HomeworksProps {
  dataSource: any;
}

const MobileStudentsTable: FC<HomeworksProps> = ({ dataSource }) => {
  return (
    <div className={styles.container}>
      {dataSource?.group?.users
        ?.sort((a: any, b: any) => {
          return a.first_name - b.first_name;
        })
        .map((user: any, index: React.Key | null | undefined) => {
          return <HomeworkCard user={user} key={index} />;
        })}
    </div>
  );
};

export default MobileStudentsTable;
