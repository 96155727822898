import { FC, useEffect, useState } from "react";
import styles from "./header.module.scss";
import useAuth from "../../../context/useAuth";
import { DrawerHeader } from "./DrawerHeader";
import { DrawerHeaderStudent } from "./DrawerHeaderStudent";
import {
  notificationNegative,
  NotificationPositive,
} from "../../Shared/Notifacation/NotificationLuca";
import useUpdatePassword from "../../../api/useUpdatePassword";
import { useNavigate } from "react-router-dom";
import avatarNew from "../../../assets/images/avatars/avatarNew.png";
import avatarS from "../../../assets/icons/teacher/avatar/teacherAvatar-S.svg";
import arrowback from "../../../assets/icons/arrow_back.svg";
import { getClassroomWithHomeworks_getClassroomWithHomeworks } from "../../../__generated__/gql-types/getClassroomWithHomeworks";
import { resolveAvatar, resolveColorAvatar } from "../../../utils/functions";
import useIsMobile from "../../../hooks/useIsMobile";
import { functionReturnImgAvatar } from "../../Store/ColumnAvatar/ContainerAvatar/utils";
import { getClothesAndAccessories_getClothesAndAccessories } from "../../../__generated__/gql-types/getClothesAndAccessories";
import { getUserScore_getUserScore } from "../../../__generated__/gql-types/getUserScore";
import UseGetClothesAndAccessories from "../../../api/useGetClothesAndAccessories";
import UseUserScore from "../../../api/useGetUserScore";

interface HeaderProps {
  userDb?: any;
  title: string;
  openCourse?: boolean;
  dataClassroom?: getClassroomWithHomeworks_getClassroomWithHomeworks;
  headerWithOutMobile?: boolean;
  back?: string;
}

const Header: FC<HeaderProps> = ({
  back,
  title,
  dataClassroom,
  openCourse,
  userDb,
  headerWithOutMobile,
}) => {
  const navigate = useNavigate();
  const getUserScore = UseUserScore();
  const changePassword = useUpdatePassword();
  const getClothesAndAccessories = UseGetClothesAndAccessories();
  const isMobile = useIsMobile();
  const { userToken, logout } = useAuth();
  const [clothesAndAccessories, setClothesAndAccessories] = useState<
    getClothesAndAccessories_getClothesAndAccessories[] | null
  >(null);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [userScore, setUserScore] = useState<getUserScore_getUserScore>();
  const [validError, setValidError] = useState({
    error: false,
    errorText: "",
  });
  const [password, setPassword] = useState({
    passwordOld: "",
    passwordNew1: "",
    passwordNew2: "",
    error: true,
  });

  const handleRedirection = () => {
    if (title === "Mis clases") {
      return navigate("/myclasses", { replace: true });
    }
    return navigate(-1);
  };

  const showDrawer = () => {
    setVisibleProfile(true);
  };

  const onClose = () => {
    setVisibleProfile(false);
  };

  const onOpenClass = () => {
    if (back === "back_points") {
      return navigate("/points", { replace: true });
    }
    if (back === "mymissions") {
      return navigate("/mymissions", { replace: true });
    }
    return navigate(-1);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const updateResponse = await changePassword({
        prevPassword: password.passwordOld,
        newPassword: password.passwordNew1,
      });

      if (updateResponse.data) {
        logout();
        NotificationPositive("¡Estupendo! La contraseña ha sido modificada.");
      }
    } catch (error) {
      notificationNegative();
    }
  };
  function handleOffFocus(event: React.ChangeEvent<HTMLInputElement>) {
    if (password.passwordNew1 !== password.passwordNew2) {
      setValidError((prevState) => ({
        ...prevState,
        error: true,
      }));
    } else setValidError((prevState) => ({ ...prevState, error: false }));
  }
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value });
  }

  useEffect(() => {
    if (password.passwordNew1 === password.passwordNew2) {
      setPassword((prevState) => ({ ...prevState, error: false }));
    } else {
      setPassword((prevState) => ({ ...prevState, error: true }));
    }
  }, [password.passwordNew1, password.passwordNew2, password.error]);

  useEffect(() => {
    if (password.passwordNew1 === password.passwordNew2) {
      setValidError((prevState) => ({ ...prevState, errorText: "" }));
    } else if (
      password.passwordNew1.length < 6 ||
      password.passwordNew2.length < 6
    ) {
      setValidError((prevState) => ({
        ...prevState,
        errorText:
          "La longitud de las contraseñas debe ser de al menos 6 caracteres.",
      }));
    } else {
      setValidError((prevState) => ({
        ...prevState,
        errorText: "Las contraseñas deben coincidir.",
      }));
    }
  }, [validError.error, password.passwordNew1, password.passwordNew2]);

  useEffect(() => {
    (async () => {
      if (userToken?.role === "student") {
        const userScoreRes = await getUserScore();

        userScoreRes && setUserScore(userScoreRes);
      }
    })();
  }, [getUserScore, userToken]);

  useEffect(() => {
    (async () => {
      if (userToken?.role === "student") {
        const response = await getClothesAndAccessories();
        const clothesActive = response?.filter((e) => e.active);
        clothesActive && setClothesAndAccessories(clothesActive);
      }
    })();
  }, [getClothesAndAccessories, userToken]);

  return (
    <div
      className={
        headerWithOutMobile ? styles.containerWithOut : styles.container
      }
      id="header"
    >
      {!dataClassroom?.classroom?.name && (
        <div className={styles.titleContainer}>
          {(openCourse || back) && (
            <img src={arrowback} alt="arrow back" onClick={onOpenClass} />
          )}
          <h1>{title}</h1>
        </div>
      )}

      {dataClassroom?.classroom?.name && (
        <div className={styles.titleContainer}>
          <img
            src={arrowback}
            alt="arrow back"
            onClick={() => handleRedirection()}
          />
          <h1 style={{ fontSize: isMobile ? "17px" : "" }}>
            {isMobile ? title : dataClassroom?.classroom?.name}
          </h1>
        </div>
      )}

      <div className={styles.rightContent}>
        <div onClick={showDrawer} className={styles.avatarContainer}>
          {userToken?.role === "student" && clothesAndAccessories && (
            <span
              className={styles.studentAvatar}
              style={{
                background: resolveColorAvatar(userToken?.avatar ?? "spica"),
              }}
            >
              {clothesAndAccessories?.map((clothes) => (
                <img
                  src={clothes.item_img ?? ""}
                  alt="avatar"
                  style={{
                    zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                  }}
                  className={styles.clothes}
                />
              ))}
              <img
                src={
                  userScore?.level && userScore?.level < 6
                    ? resolveAvatar(
                        userScore?.user?.avatar_state?.name ?? "polux",
                        userScore?.level.toString() ?? "1"
                      )
                    : functionReturnImgAvatar(
                        userScore?.user?.avatar_state?.name ?? ""
                      )
                }
                alt="avatar"
                className={
                  userScore?.level && userScore?.level < 6
                    ? styles.avatarImgwithOutLevel // revisar
                    : styles.avatarImg
                }
              />
            </span>
          )}
          {userToken?.role === "teacher" && (
            <>
              {!isMobile &&
                (userToken?.role === "teacher" ||
                  userToken?.role === "admin") && (
                  <img
                    src={avatarNew}
                    alt="avatar"
                    style={{ cursor: "pointer", width: "50px" }}
                  />
                )}
              {isMobile && (
                <img src={avatarS} alt="avatar" style={{ cursor: "pointer" }} />
              )}
            </>
          )}
        </div>

        {(userToken?.role === "teacher" || userToken?.role === "admin") &&
          visibleProfile && (
            <DrawerHeader
              onClose={onClose}
              visible={visibleProfile}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleOffFocus={handleOffFocus}
              password={password}
              validError={validError}
            />
          )}
        {userToken?.role === "student" && visibleProfile && (
          <DrawerHeaderStudent
            title={title}
            userDb={userDb}
            visible={visibleProfile}
            onClose={onClose}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleOffFocus={handleOffFocus}
            password={password}
            validError={validError}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
