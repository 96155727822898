import { useEffect, useState } from "react";
import styles from "./missions.module.scss";
// Components
import { Banner } from "../Shared/Banner";
import { Header } from "../../Layout/Header";
import { SingleCourse } from "./SingleCourse";

// API && Types
import { getClassroomsByStudentIdV2_getClassroomsByStudentIdV2 as PropsClassRooms } from "./../../../__generated__/gql-types/getClassroomsByStudentIdV2";
import { getHomeworksByClassroom_getHomeworksByClassroom as PropsHomeworks } from "./../../../__generated__/gql-types/getHomeworksByClassroom";
import { Skeleton } from "antd";
import useIsMobile from "../../../hooks/useIsMobile";
import { ModalError } from "./NewQuizz/ModalSave";
import { BannerCardsNew } from "./SingleCourse/BannerCards";
import { Tabs } from "./SingleCourse/BannerCards/Tabs";
import { useAppSelector } from "../../../redux/hooks";
import { DependencyRefresh } from "../../../redux/store/store";
import useAuth from "../../../context/useAuth";
import { encontrarValorMayor } from "./utils";
import UseClassroomsByStudentIdV2 from "../../../api/useGetClassroomsByStudentIdV2";
import UseGetHomeworksByClassroom from "../../../api/useGetHomeworksByClassroom";

const Missions = () => {
  const getClassroomsByStudentIdV2 = UseClassroomsByStudentIdV2();
  const getHomeworksByClassroom = UseGetHomeworksByClassroom();
  const isMobile = useIsMobile();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const { userToken } = useAuth();
  const [isLoadingClassrooms, setIsLoadingClassrooms] =
    useState<boolean>(false);
  const [isLoadingHomeworks, setIsLoadingHomeworks] = useState<boolean>(false);
  const [classRooms, setClassRooms] = useState<PropsClassRooms[] | null>();
  const [homeworks, setHomeworks] = useState<(PropsHomeworks | null)[]>([]);
  const [misionPending, setMisionPending] = useState<PropsHomeworks[]>([]);
  const [openModalError, setOpenModalError] = useState(false);
  const [contReviewStudent, setContReviewStudent] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [completedTasks, setCompletedTasks] = useState<PropsHomeworks[] | null>(
    null
  );
  const [needReviewTasks, setNeedReviewTasks] = useState<
    PropsHomeworks[] | null
  >(null);
  const [redactionTasks, setRedactionTasks] = useState<PropsHomeworks[] | null>(
    null
  );

  const onCloseModalDelete = () => {
    setOpenModalError(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoadingClassrooms(true);
      try {
        const classRoomRes = await getClassroomsByStudentIdV2();
        setClassRooms(classRoomRes);
        setIsLoadingClassrooms(false);
      } catch (error) {
        setIsLoadingClassrooms(false);
        setErrorMessage("El usuario no tiene ningún grupo asignado.");
        setOpenModalError(true);
      }
    })();
  }, [getClassroomsByStudentIdV2]);

  useEffect(() => {
    (async () => {
      setIsLoadingHomeworks(true);
      try {
        if (classRooms) {
          const homeworks = classRooms.map((classroom) => {
            return getHomeworksByClassroom({
              classroomId: Number(classroom.id),
            });
          });
          const homeworksResolveSnap = await Promise.all(homeworks);
          const homeworksResolve = homeworksResolveSnap
            .flat()
            .filter((homework) => homework);

          setHomeworks(homeworksResolve);

          let cont = 0;
          let misionPen: PropsHomeworks[] = [];
          let misionNeed: PropsHomeworks[] = [];
          let misionCompleted: PropsHomeworks[] = [];
          let misionRedactionFinished: PropsHomeworks[] = [];

          homeworksResolve?.forEach((homework) => {
            const maxResult = encontrarValorMayor(
              homework?.result ?? 0,
              homework?.post_homework_result ?? 0
            );
            if (
              homework?.users?.length === 0 ||
              homework?.users?.find(
                (user) => Number(user.id) === Number(userToken?.id)
              )
            ) {
              if (
                homework?.id &&
                homework?.lessons &&
                homework?.lessons[0]?.lesson_type === "redaction" &&
                !homework?.redaction_review_by_student &&
                homework?.redaction_correction
              ) {
                cont++;
              }

              if (
                homework?.lessons &&
                homework.lessons[0].lesson_type === "redaction" &&
                homework.cta_button !== "TERMINAR" &&
                homework.cta_button !== "TERMINAREVIDENCIA" &&
                (homework?.finished || homework.cta_button === "NO ENTREGADA")
              ) {
                misionRedactionFinished.push(homework);
              }

              if (
                homework.cta_button === "TERMINAR" ||
                homework.cta_button === "TERMINAREVIDENCIA" ||
                (homework.status === "inCourse" &&
                  homework.finished !== true &&
                  (!homework?.tries ||
                    (homework?.tries &&
                      homework?.tries - Number(homework?.studentTries) > 0)))
              ) {
                misionPen.push(homework);
              }

              if (
                maxResult &&
                maxResult >= 6 &&
                homework?.finished &&
                homework.lessons &&
                homework.lessons[0].lesson_type !== "redaction"
              ) {
                misionCompleted.push(homework);
              }
              if (
                (!maxResult || maxResult < 6) &&
                (homework?.status === "finished" || homework?.finished) &&
                homework.lessons &&
                homework.lessons[0].lesson_type !== "redaction"
              ) {
                misionNeed.push(homework);
              }
            }
          });
          setContReviewStudent(cont);
          setRedactionTasks(
            misionRedactionFinished.sort(
              (a, b) =>
                new Date(b?.completion_date).getTime() -
                new Date(a?.completion_date).getTime()
            )
          );
          setCompletedTasks(
            misionCompleted.sort(
              (a, b) =>
                new Date(b?.completion_date).getTime() -
                new Date(a?.completion_date).getTime()
            )
          );
          setNeedReviewTasks(
            misionNeed.sort(
              (a, b) =>
                new Date(b?.completion_date).getTime() -
                new Date(a?.completion_date).getTime()
            )
          );
          setMisionPending(
            misionPen.sort(
              (a, b) =>
                new Date(b?.completion_date).getTime() -
                new Date(a?.completion_date).getTime()
            )
          );
        }
      } catch (error) {
        setOpenModalError(true);
      }
      setIsLoadingHomeworks(false);
    })();
  }, [getHomeworksByClassroom, userToken, dependencyRefresh, classRooms]);

  return (
    <div className={!isMobile ? styles.contentLayout : ""}>
      {classRooms && (
        <>
          {!isMobile && <Header userDb={userToken} title={"Mis Misiones"} />}
          {isLoadingClassrooms ? (
            <div className={styles.spinner}>
              <Skeleton
                active
                paragraph={{ rows: 1 }}
                style={{ margin: "24px 0" }}
              />
              <Skeleton
                active
                paragraph={{ rows: 5 }}
                style={{ margin: "24px 0" }}
              />
              <Skeleton
                active
                paragraph={{ rows: 7 }}
                style={{ margin: "24px 0" }}
              />
            </div>
          ) : (
            <Banner homeworks={homeworks} classRooms={classRooms} />
          )}
          {isLoadingHomeworks ? (
            <div className={styles.spinner}>
              <Skeleton
                active
                paragraph={{ rows: 1 }}
                style={{ margin: "24px 0" }}
              />
              <Skeleton
                active
                paragraph={{ rows: 5 }}
                style={{ margin: "24px 0" }}
              />
              <Skeleton
                active
                paragraph={{ rows: 7 }}
                style={{ margin: "24px 0" }}
              />
            </div>
          ) : (
            <>
              <BannerCardsNew itemFiltered={misionPending} />
              <Tabs
                completedTasks={completedTasks}
                needReviewTasks={needReviewTasks}
                redactionTasks={redactionTasks}
                contReviewStudent={contReviewStudent}
              />
            </>
          )}
        </>
      )}
      <ModalError
        onCloseModalDelete={onCloseModalDelete}
        open={openModalError}
        message={errorMessage}
      ></ModalError>
    </div>
  );
};

export default Missions;
