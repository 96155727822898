import React, { FC } from "react";
import { getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2 } from "../../../../../../../__generated__/gql-types/getResponsesByHomeworkAndUserV2";
import styles from "./orderFrase.module.scss";

interface PropsOrderFrase {
  index: number;
  question: getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2;
  subject_id?: number | null;
}

const OrderFrase: FC<PropsOrderFrase> = ({ index, question, subject_id }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{index + 1}. Ordenar la frase.</div>
        <div className={styles.containerBlock}>
          {question.answers?.map((answers) => (
            <div
              className={
                answers.correct
                  ? styles.containerAnswer
                  : styles.containerAnswerFalse
              }
            >
              {answers.answer}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderFrase;
