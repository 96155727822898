import {
  getLessonById_getLessonById,
  getLessonById_getLessonById_lesson_content_quizzes,
} from "../../../../../__generated__/gql-types/getLessonById";

export const concatQuizzes = (
  individualLesson: getLessonById_getLessonById | null
) => {
  let intraVideoQuiz: getLessonById_getLessonById_lesson_content_quizzes[] = [];
  let quizzes: getLessonById_getLessonById_lesson_content_quizzes[] = [];
  let contQuestions = 0;

  if (
    individualLesson &&
    individualLesson.lesson_content &&
    individualLesson.lesson_content.videos &&
    individualLesson.lesson_content.videos[0] &&
    individualLesson.lesson_content.videos[0].quizzes &&
    individualLesson.lesson_content.videos[0].quizzes.length > 0
  ) {
    intraVideoQuiz = individualLesson.lesson_content.videos[0].quizzes.map(
      (q) => {
        if (q.quiz && q.quiz.questions && q.quiz.questions.length > 0)
          contQuestions += q.quiz.questions.length;
        return q.quiz as unknown as getLessonById_getLessonById_lesson_content_quizzes;
      }
    );
  }

  if (
    individualLesson &&
    individualLesson.lesson_content &&
    individualLesson.lesson_content.quizzes &&
    individualLesson.lesson_content.quizzes.length > 0
  ) {
    quizzes = individualLesson?.lesson_content?.quizzes?.map((q) => {
      if (q && q.questions && q.questions.length > 0)
        contQuestions += q.questions.length;
      return q;
    });
  }

  return { quizzes: intraVideoQuiz.concat(quizzes), count: contQuestions };
};
