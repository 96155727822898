import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getQuestionResult,
  getQuestionResultVariables,
} from "../__generated__/gql-types/getQuestionResult";

const UseGetQuestionResultAI = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getQuestionResultVariables) => {
      const result: ApolloQueryResult<getQuestionResult> = await client.query({
        query: GET_QUESTION_RESULT_AI,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getQuestionResult;
    },
    [client]
  );
};

export default UseGetQuestionResultAI;

const GET_QUESTION_RESULT_AI = gql`
  query getQuestionResult(
    $question: String
    $modelResponse: String
    $studentResponse: String
  ) {
    getQuestionResult(
      question: $question
      modelResponse: $modelResponse
      studentResponse: $studentResponse
    )
  }
`;
