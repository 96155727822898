import { FC, useState, useEffect } from "react";
import { Progress } from "antd";
import styles from "./finishLesson.module.scss";
import { useNavigate } from "react-router-dom";
import NextLevel from "../../../Shared/NextLevel/NextLevel";

// Api && Types
import { getUserByEmail_getUserByEmail } from "../../../../../__generated__/gql-types/getUserByEmail";

// Iconos
import cross from "../../../../../assets/images/cross.svg";
import frame from "../../../../../assets/images/frameClean.svg";
import arrow from "../../../../../assets/icons/arrowReturn.svg";
import blueDiamond from "../../../../../assets/icons/students/blueDiamond.svg";
import finishedMission from "../../../../../assets/images/finished_mission.svg";
import useGetHomeworkTriesForFinishedHomeworkAction from "../../../../../api/useGetHomeworkTriesForFinishedHomeworkAction";
import { DrawerHomework } from "../../DrawerHomeworkStudent";
import UseGetHomeworkByIdForStudent from "../../../../../api/useGetHomeworkByIdForStudent";
import { getHomeworkByIdForStudent_getHomeworkByIdForStudent } from "../../../../../__generated__/gql-types/getHomeworkByIdForStudent";
import { getHomeworkForLessonResolveDeepLink_getHomeworkForLessonResolveDeepLink } from "../../../../../__generated__/gql-types/getHomeworkForLessonResolveDeepLink";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";

interface PropsResults {
  puntos: any;
  userToken: getUserByEmail_getUserByEmail | null;
  homeworkParams: string | null;
  individualLesson: getLessonById_getLessonById | null;
  statusHomework: getHomeworkForLessonResolveDeepLink_getHomeworkForLessonResolveDeepLink | null;
}

const FinishLesson: FC<PropsResults> = ({
  puntos,
  individualLesson,
  userToken,
  statusHomework,
  homeworkParams,
}) => {
  const navigate = useNavigate();
  const [openNextLevel, setOpenNextLevel] = useState<boolean>(false);
  const [newStudentTries, setNewStudentTries] = useState<number>();

  const [visibleLesson, setVisibleLesson] = useState(false);
  let aprobado = (puntos?.result ?? 1) >= 6;
  const getHomeworkTriesForFinished =
    useGetHomeworkTriesForFinishedHomeworkAction();
  const getHomeworkByIdForStudent = UseGetHomeworkByIdForStudent();
  const [homework, setHomework] =
    useState<getHomeworkByIdForStudent_getHomeworkByIdForStudent | null>(null);

  useEffect(() => {
    (async () => {
      if (homeworkParams) {
        const response = await getHomeworkTriesForFinished({
          homeworkId: homeworkParams,
        });
        (response || response === 0) && setNewStudentTries(response);
      }
    })();
  }, [getHomeworkTriesForFinished, homeworkParams]);

  useEffect(() => {
    (async () => {
      if (homeworkParams) {
        const response = await getHomeworkByIdForStudent({
          homeworkId: homeworkParams,
        });
        response && setHomework(response);
      }
    })();
  }, [homeworkParams, getHomeworkByIdForStudent]);

  const onCloseLesson = () => {
    setVisibleLesson(false);
  };

  const handleRedirection = () => {
    return navigate(-1);
  };

  return !statusHomework?.post_homework && openNextLevel ? (
    <NextLevel userDb={userToken} handleRedirection={handleRedirection} />
  ) : (
    <div className={styles.container}>
      {newStudentTries !== 0 || userToken?.role === "teacher" ? (
        <div className={styles.section}>
          <a className={styles.try} href={`${window.location.href}`}>
            <img src={arrow} alt="" />
            <h1>
              {individualLesson?.subject_id === 16
                ? "TRY AGAIN"
                : "INTENTAR DE NUEVO"}
            </h1>
          </a>
          <img
            src={cross}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleRedirection}
          />
        </div>
      ) : (
        <div className={styles.section}>
          <img
            src={cross}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleRedirection}
          />
        </div>
      )}

      <div className={styles.center}>
        {aprobado ? (
          <img src={frame} alt="aprobado" className={styles.imageAprobado} />
        ) : (
          <img
            src={finishedMission}
            className={styles.imageDesaprobado}
            alt="vuelva-a-intentar"
          />
        )}
        <div className={styles.nota}>
          {aprobado
            ? "MISIÓN SUPERADA!"
            : individualLesson?.subject_id === 16
            ? "MISSION COMPLETE"
            : "MISIÓN FINALIZADA"}
        </div>
        <div className={styles.progress}>
          <Progress
            percent={(puntos?.result ?? 1) * 10}
            showInfo={false}
            trailColor={"#CBCEDE"}
            strokeColor={aprobado ? "#3843D0" : "#E33B57"}
            className="progressFinishLesson"
          />
          <p
            style={{
              color: puntos?.result === 10 ? "white" : "#474747",
            }}
          >
            {puntos?.result}/10
          </p>
        </div>
        {puntos?.maxResultHomework && (
          <div className={styles.subNota}>
            Tu mejor puntuación anterior fue de {puntos?.maxResultHomework}/10.
          </div>
        )}
      </div>
      <div className={styles.containerFooter}>
        {userToken?.role !== "teacher" && (
          <div className={styles.firstBox}>
            {puntos?.maxResultHomework ? (
              puntos?.maxResultHomework < puntos.result ? (
                <>
                  <div>Conseguiste </div>
                  <div>
                    <img
                      src={blueDiamond}
                      alt="zafire"
                      className={styles.imageGem}
                    />
                    <span className={styles.textPoints}>
                      + {(puntos?.result - puntos?.maxResultHomework) * 10}{" "}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div>No conseguiste más cristales </div>
                  <div className={styles.secondColumn}>
                    <img
                      src={blueDiamond}
                      alt="zafire"
                      className={styles.imageGem}
                    />
                    <span className={styles.textPoints}>0</span>
                  </div>
                </>
              )
            ) : (
              <>
                <div>Conseguiste </div>
                <div>
                  <img
                    src={blueDiamond}
                    alt="zafire"
                    className={styles.imageGem}
                  />
                  <span className={styles.textPoints}>
                    {puntos?.result ? "+" : ""}{" "}
                    {puntos?.result ? puntos?.result * 10 : 0}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
        {homework?.needs_file_evidence && (
          <div className={styles.textNeedEvidence}>
            Recuerda que para completar la misión tu profesor te ha pedido que
            subas una evidencia. Si no lo has hecho, puedes hacerlo ahora.
          </div>
        )}

        <div className={styles.footer}>
          {homework?.needs_file_evidence && (
            <button
              className={styles.buttonEvidence}
              onClick={() => setVisibleLesson(true)}
            >
              ADJUNTAR EVIDENCIA
            </button>
          )}
          {/* {userDb?.score?.level &&
          userDb?.score?.level !== userToken?.score?.level ? (
            <button
              className={styles.nextButton}
              onClick={() => {
                setOpenNextLevel(!openNextLevel);
                handleRedirection();
              }}
            >
              CONTINUAR
            </button>
          ) : ( */}
          <button className={styles.nextButton} onClick={handleRedirection}>
            {individualLesson?.subject_id === 16 ? "CONTINUE" : "CONTINUAR"}
          </button>
          {/* )} */}
        </div>
      </div>
      {visibleLesson && (
        <DrawerHomework
          visibleLesson={visibleLesson}
          onCloseLesson={onCloseLesson}
          homework_id={homework?.id}
          finishedLesson={true}
        />
      )}
    </div>
  );
};

export default FinishLesson;
