import { FC, useEffect, useState } from "react";
import { Select, Skeleton } from "antd";
import styles from "./singleClass.module.scss";
import type { SorterResult } from "antd/es/table/interface";

// Components
import { TaskTable } from "./TaskTable";
import { Header } from "../Layout/Header";
import { CalificationsTable } from "./CalificationsTable";
import {
  TaskActiveFilters,
  StudentCalificationFilters,
  Filters,
} from "./Filters";
import {
  MobileHomeworksTable,
  MobileStudentsTable,
} from "../Mobile/Teacher/Clasess";
import { FilterDrawerMobile } from "../Teachers/Classes/SingleClass/Filters";
import AssingHomeworkDrawer from "../Homework/Drawer/AssingHomeworkDrawer";

import settingsIcon from "../../assets/icons/settingsIcon.svg";
import leftarrow from "../../assets/icons/leftarrow.svg";

// Api & Types
import { getLessonById_getLessonById } from "../../__generated__/gql-types/getLessonById";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { environment } from "../../configuration/environment";
import StudentsTable from "./StudentsTable/StudentsTable";
import StudentsAccountModal from "./StudentsAccountModal/StudentsAccountModal";
import DownloadTableModal from "./DownloadTableModal/DownloadTableModal";
import {
  getClassroomWithHomeworks_getClassroomWithHomeworks,
  getClassroomWithHomeworks_getClassroomWithHomeworks_classroom_group_users,
} from "../../__generated__/gql-types/getClassroomWithHomeworks";
import UseClassroomWithHomeworks from "../../api/useGetClassroomWithHomeworks";

import VideoLessonsTable from "./VideoLessonsTable/VideoLessonsTable";
import UseGetClassroomLessons from "../../api/useGetClassroomLesson";
import useIsMobile from "../../hooks/useIsMobile";

interface Sorted {
  setSortedInfo: (e: string) => void;
  sortedInfo: {};
}

const SingleClass: FC = () => {
  const location = useLocation();
  let { id } = useParams();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const getClassroomWithHomeworks = UseClassroomWithHomeworks();
  const getClassroomLessons = UseGetClassroomLessons();
  const queryParams = new URLSearchParams(location.search);
  const selectedParams = queryParams.get("selectedItem");
  const isMobile = useIsMobile();
  const [isStudentsTableLoading, setIsStudentsTableLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>(
    selectedParams ? selectedParams : "tareas"
  );
  const [selectedStatusTask, setSelectedStatusTask] = useState<string>(
    searchParams.get("filter") ?? "all"
  );

  const [filterActive, setFilterActive] = useState<boolean>(
    searchParams.get("filter") ? true : false
  );
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Sorted>>({
    order: "descend",
    columnKey: "utc_start_date",
  });

  const [showAssignHomework, setShowAssignHomework] = useState<boolean>(false);

  const [selectedStatusHomework, setSelectedStatusHomework] = useState([
    "inCourse",
    "finished",
    "programed",
  ]);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  // Query Lessons States

  const [individualClassroom, setIndividualClassroom] =
    useState<getClassroomWithHomeworks_getClassroomWithHomeworks | null>(
      location.state?.item ?? null
    );

  const [dataCalificationsTable, setDataCalificationsTable] = useState();
  const [
    dataCalificationsTableToDownload,
    setDataCalificationsTableToDownload,
  ] = useState();
  // Pagination
  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(
      location.state?.lesson ?? null
    );
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contTask, setContTask] = useState(0);

  //students
  const [addStudentAccount, setAddStudentAccount] = useState(false);
  const [updateStudentAccount, setUpdateStudentAccount] = useState(false);
  const [studentId, setStudentId] = useState<string>();
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const [students, setStudents] =
    useState<
      getClassroomWithHomeworks_getClassroomWithHomeworks_classroom_group_users[]
    >();

  //video lessons
  const [videoLessons, setVideoLessons] = useState<any>();

  const onCloseAssignHomeworkDrawer = () => {
    setShowAssignHomework(false);
  };

  const onClose = () => {
    setIndividualLesson(null);
  };
  const getDataCalificationsTable = async (
    classRoomId: number,
    status: any,
    startDate: any,
    endDate: any
  ) => {
    try {
      const axiosOptions = {
        method: "POST",
        url: `${environment.backendUrl}/ClassoomStudentsWithHomeworks/${classRoomId}`,
        data: {
          status: status?.length > 0 ? status : selectedStatusHomework,
          from: startDate !== undefined ? startDate?.toString() : "", //"2023-03-01T17:07:15Z",
          to: endDate !== undefined ? endDate?.toString() : "", //"2023-03-04T17:07:30Z"
        },
      };
      let response = await axios.request(axiosOptions);
      setDataCalificationsTable(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getDataCalificationsTableToDownload = async (classRoomId: number) => {
    try {
      const axiosOptions = {
        method: "POST",
        url: `${environment.backendUrl}/ClassoomStudentsWithHomeworks/${classRoomId}`,
        data: {
          all: true,
        },
      };
      let response = await axios.request(axiosOptions);
      setDataCalificationsTableToDownload(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //DownloadTableaAsExcel data

  useEffect(() => {
    setContTask(
      individualClassroom?.homeworks?.filter((a) => a.deleted_at === null)
        .length || 0
    );
  }, [individualClassroom]);

  const handleSelectTabs = (e: any) => {
    const params = new URLSearchParams(location.search);
    params.set("selectedItem", e);
    navigate(`?selectedItem=${e}`);

    setSelectedItem(e);
  };

  useEffect(() => {
    const fetchHomework = async () => {
      if (id) {
        const studentsClassroomHomeworks = await getClassroomWithHomeworks({
          classroomId: id,
        });
        setIndividualClassroom(studentsClassroomHomeworks);
        studentsClassroomHomeworks?.classroom?.group?.users &&
          setStudents(studentsClassroomHomeworks?.classroom?.group?.users);
      }
    };
    fetchHomework();
    setIsLoading(false);
  }, [
    id,
    setIndividualClassroom,
    setIsLoading,
    setStudents,
    getClassroomWithHomeworks,
  ]);

  useEffect(() => {
    (async () => {
      const classRoomLessons = await getClassroomLessons();
      const filteredClassroomLessons = classRoomLessons?.filter((lesson) => {
        return (
          lesson?.classroom_id?.toString() ===
          individualClassroom?.classroom?.id
        );
      });
      setVideoLessons(filteredClassroomLessons);
    })();
  }, [individualClassroom, getClassroomLessons]);
  useEffect(() => {
    (async () => {
      getDataCalificationsTable(
        Number(id),
        selectedStatusHomework,
        selectedFromDate,
        selectedToDate
      );
    })();
    (async () => {
      getDataCalificationsTableToDownload(Number(id));
    })();
  }, []);

  return (
    <div className={styles.container}>
      {individualClassroom ? (
        <Header
          title={individualClassroom?.classroom?.name ?? "Name"}
          back={"back"}
          dataClassroom={individualClassroom}
        />
      ) : (
        <Skeleton active paragraph={{ rows: 1 }} style={{ margin: "24px 0" }} />
      )}
      {isMobile && (
        <Select
          defaultValue="Tareas"
          style={{
            width: "95%",
            color: "#3843D0",
            borderWidth: "1px solid #CBCEDE",
            backgroundColor: "#FFF",
            marginLeft: "2%",
            marginTop: "2%",
            marginBottom: "2%",
            fontFamily: "Poppins-Bold",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "22.4px",
          }}
          dropdownStyle={{
            backgroundColor: "#FFF",
            color: "#3843D0",
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "22.4px",
          }}
          onChange={handleSelectTabs}
          options={[
            {
              value: "tareas",
              label: "Tareas",
            },
            {
              value: "videoLecciones",
              label: "Lecciones Planeadas",
            },
            {
              value: "calificaciones",
              label: "Calificaciones",
            },

            {
              value: "alumnos",
              label: "Alumnos",
            },
          ]}
        />
      )}

      <div className={styles.filtersAndTableContainer}>
        {openFilter && !isMobile && (
          <div className={styles.filtersContainer}>
            <button
              name="filters"
              className={styles.itemsFilter}
              onClick={() => setOpenFilter(false)}
            >
              <span className={styles.itemFilter}>
                <img
                  src={settingsIcon}
                  alt=""
                  style={{ marginRight: "10px" }}
                />
                Filtros
              </span>
              <img src={leftarrow} alt="" />
            </button>
            {selectedItem === "tareas" && individualClassroom && (
              <TaskActiveFilters
                filterActive={filterActive}
                selectedStatusTask={selectedStatusTask}
                setSelectedStatusTask={setSelectedStatusTask}
                setSortedInfo={setSortedInfo}
                sortedInfo={sortedInfo}
              />
            )}

            {selectedItem === "calificaciones" && individualClassroom && (
              <StudentCalificationFilters
                selectedStatusHomework={selectedStatusHomework}
                selectedFromDate={selectedFromDate}
                setSelectedFromDate={setSelectedFromDate}
                selectedToDate={selectedToDate}
                setSelectedToDate={setSelectedToDate}
                getCalificationsData={getDataCalificationsTable}
                individualClassroom={individualClassroom}
              />
            )}
          </div>
        )}

        <div
          className={styles.tableContainer}
          style={{
            width:
              openFilter && selectedItem === "calificaciones"
                ? "calc(100% - 20%)"
                : "100%",
          }}
        >
          {!isMobile && (
            <Filters
              setAddStudentAccount={setAddStudentAccount}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              totalTask={contTask}
              totalStudents={
                individualClassroom?.classroom?.group?.users?.length
              }
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              setIsDownloadModalOpen={setIsDownloadModalOpen}
              totalClassroomLessons={
                videoLessons?.filter((lesson: any) => lesson.lesson !== null)
                  .length
              }
            />
          )}
          {selectedItem === "tareas" && individualClassroom && (
            <div className={styles.subContainer}>
              {isMobile && (
                <FilterDrawerMobile
                  type="homework"
                  sortedInfo={sortedInfo}
                  filterActive={filterActive}
                  setSortedInfo={setSortedInfo}
                  setFilterActive={setFilterActive}
                  selectedStatusTask={selectedStatusTask}
                  setSelectedStatusTask={setSelectedStatusTask}
                />
              )}

              {!isMobile ? (
                <div
                  className={
                    filterActive
                      ? styles.tableTaskContainerActive
                      : styles.tableTaskContainer
                  }
                >
                  {id && (
                    <TaskTable
                      classroomId={id}
                      setIndividualClassroom={setIndividualClassroom}
                      dataSource={individualClassroom}
                      selectedStatusTask={selectedStatusTask}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  )}
                </div>
              ) : (
                <MobileHomeworksTable
                  sortedInfo={sortedInfo}
                  dataSource={individualClassroom}
                  selectedStatusTask={selectedStatusTask}
                />
              )}
            </div>
          )}
          {selectedItem === "calificaciones" && individualClassroom && (
            <div className={styles.subContainer}>
              {isMobile && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop: 40,
                    paddingTop: 40,
                    paddingBottom: 40,
                    paddingLeft: 30,
                    paddingRight: 30,
                    borderRadius: 12,
                    backgroundColor: "#FFEBC2",
                    textAlign: "center",
                    color: "#A35F00",
                    marginLeft: "25%",
                  }}
                >
                  Para ver las calificaciones que han obtenido tus alumnos en
                  sus tareas, accede a la plataforma web de Luca.
                </div>
              )}

              {!isMobile ? (
                <div
                  className={
                    openFilter
                      ? styles.tableStudentContainerActive
                      : styles.tableStudentContainer
                  }
                >
                  <CalificationsTable
                    dataSource={dataCalificationsTable}
                    isLoading={isLoading}
                  />
                  <div
                    className={styles.calificationTableLabels}
                    style={openFilter ? { display: "none" } : {}}
                  >
                    <div className={styles.calificationTableInsideLabels}>
                      <div>× NO HECHA</div>{" "}
                    </div>
                    <div className={styles.calificationTableInsideLabels}>
                      <div>• NO APLICA</div>
                    </div>
                    <div className={styles.calificationTableInsideLabels}>
                      <div>– – NO FINALIZADA</div>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <MobileStudentsTable dataSource={dataCalificationsTable} />
              )}
            </div>
          )}
          {selectedItem === "videoLecciones" && individualClassroom && (
            <div className={styles.subContainer}>
              <div
                className={
                  filterActive
                    ? styles.tableTaskContainerActive
                    : styles.tableTaskContainer
                }
              >
                <VideoLessonsTable
                  classroomStudents={videoLessons}
                  isStudentsTableLoading={isStudentsTableLoading}
                />
              </div>
            </div>
          )}
          {selectedItem === "alumnos" && individualClassroom && (
            <div className={styles.subContainer} style={{ width: "100%" }}>
              <div
                className={
                  filterActive
                    ? styles.tableTaskContainerActive
                    : styles.tableTaskContainer
                }
              >
                <StudentsTable
                  classroomStudents={students}
                  setUpdateStudentAccount={setUpdateStudentAccount}
                  setStudentId={setStudentId}
                  dataCalificationsTableToDownload={
                    dataCalificationsTableToDownload
                  }
                  isStudentsTableLoading={isStudentsTableLoading}
                />
              </div>
              {addStudentAccount && (
                <StudentsAccountModal
                  visible={addStudentAccount}
                  setAddStudentModal={setAddStudentAccount}
                  classRoom={individualClassroom}
                  setStudents={setStudents}
                  setIsStudentsTableLoading={setIsStudentsTableLoading}
                />
              )}
              {updateStudentAccount && (
                <StudentsAccountModal
                  visible={updateStudentAccount}
                  setAddStudentModal={setUpdateStudentAccount}
                  setIsStudentsTableLoading={setIsStudentsTableLoading}
                  student={students?.filter((student: any) => {
                    return student.id === studentId;
                  })}
                  classRoom={individualClassroom}
                  setStudents={setStudents}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {showAssignHomework && (
        <AssingHomeworkDrawer
          onCloseDrawerLesson={onClose}
          onClose={onCloseAssignHomeworkDrawer}
          visible={showAssignHomework}
          incomingSelectedLesson={individualLesson}
        />
      )}
      {isDownloadModalOpen && (
        <DownloadTableModal
          visible={isDownloadModalOpen}
          setIsDownloadModalOpen={setIsDownloadModalOpen}
          dataCalificationsTableToDownload={dataCalificationsTable}
          classRoom={individualClassroom}
          filterDatesRange={`${selectedFromDate}-${selectedToDate}`}
          homeworkState={selectedStatusHomework}
        />
      )}
    </div>
  );
};

export default SingleClass;
