import { Modal } from "antd";
import React, { FC } from "react";
import styles from "./modalImg.module.scss";

interface PropsModalImg {
  open: boolean;
  setOpen: (e: boolean) => void;
  urlImg: string;
}
const ModalImg: FC<PropsModalImg> = ({ open, setOpen, urlImg }) => {
  return (
    <Modal
      title=""
      centered
      open={open}
      styles={{
        content: {
          minHeight: "90%",
          minWidth: "90%",
          width: "auto",
        },
      }}
      width={"450px"}
      footer={false}
      onCancel={() => setOpen(false)}
    >
      <img src={urlImg} alt="imagen" className={styles.imgModal} />
    </Modal>
  );
};

export default ModalImg;
