import { FC } from "react";
import styles from "./questionAI.module.scss";
import { getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2 } from "../../../../../../../__generated__/gql-types/getResponsesByHomeworkAndUserV2";

interface PropsFill {
  index: number;
  subject_id?: number | null;
  question: getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2;
}

const QuestionAI: FC<PropsFill> = ({ index, question, subject_id }) => {
  const questionText = question.question;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}. {questionText}
        </div>
        {question.url && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.url ?? ""}
              alt="questionImage"
            />
          </div>
        )}
      </div>
      <div className={styles.bottomInfoContainer}>
        {question.answers && question?.answers[0]?.AI_evaluation !== 1 && (
          <div className={styles.containerRM}>
            <div className={styles.columnRM}>R.M.</div>
            <div className={styles.columnResponse}>
              {question.answers && question.answers[0].answer}
            </div>
          </div>
        )}
      </div>
      <div className={styles.bottomInfoContainer}>
        <div
          className={
            question.answers && question?.answers[0]?.AI_evaluation === 0.5
              ? `${styles.studentResponse} ${styles.yellow}`
              : question.answers && question?.answers[0]?.AI_evaluation === 0
              ? `${styles.studentResponse} ${styles.red}`
              : `${styles.studentResponse} ${styles.green}`
          }
        >
          {question.answers && question.answers[0].student_response}
        </div>
      </div>
    </div>
  );
};

export default QuestionAI;
