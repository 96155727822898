import { FC } from "react";
import { Badge } from "antd";
import settingsIcon from "../../../assets/icons/settingsIcon.svg";
import styles from "./filters.module.scss";

import useTeacherNavigateCalifications from "../../../api/useTeacherNavigateCalifications";
import useTeacherDownloadCalifications from "../../../api/useTeacherDownloadCalifications";
import useIsMobile from "../../../hooks/useIsMobile";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation, useNavigate } from "react-router-dom";

interface FiltersProps {
  setSelectedItem: (e: string) => void;
  selectedItem?: string;
  totalTask: number | undefined;
  totalStudents: number | undefined;
  setOpenFilter: (e: boolean) => void;
  openFilter: boolean;
  addStudentAccount?: boolean;
  setAddStudentAccount: (e: boolean) => void;
  setIsDownloadModalOpen: (e: boolean) => void;
  setIndividualClassroom?: (
    e: any //getClassroomsByTeacherId_getClassroomsByTeacherId
  ) => void;
  totalClassroomLessons?: number;
}

const Filters: FC<FiltersProps> = ({
  setSelectedItem,
  selectedItem,
  totalTask,
  totalStudents,
  openFilter,
  setOpenFilter,
  setAddStudentAccount,
  setIsDownloadModalOpen,
  totalClassroomLessons,
}) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const navigateCalificationsLog = useTeacherNavigateCalifications();
  const teacherDownloadCalificationsLog = useTeacherDownloadCalifications();
  const flags = useFlags();
  const handleSearchClick = (selectedItem: string) => {
    const params = new URLSearchParams(location.search);
    params.set("selectedItem", selectedItem);
    navigate(`?selectedItem=${selectedItem}`);
  };
  return (
    <div className={styles.container}>
      <div className={styles.leftItems}>
        {!openFilter && !isMobile && selectedItem !== "alumnos" && (
          <button
            name="filters"
            className={styles.items}
            onClick={() => setOpenFilter(true)}
          >
            <img src={settingsIcon} alt="" style={{ marginRight: "10px" }} />
            Filtros
          </button>
        )}

        <button
          className={
            selectedItem === "tareas"
              ? styles.itemSelectedTask
              : styles.itemTask
          }
          onClick={() => {
            handleSearchClick("tareas");
            setSelectedItem("tareas");
          }}
        >
          {selectedItem === "tareas" ? (
            <Badge
              count={totalTask === 0 ? "00" : totalTask}
              style={{
                backgroundColor: "#3843D0",
                borderColor: "#3843D0",
                borderRadius: "14px",
                marginRight: "4px",
              }}
            />
          ) : (
            <Badge
              count={totalTask === 0 ? "00" : totalTask}
              style={{
                backgroundColor: "#6A6F8A",
                borderColor: "#6A6F8A",
                borderRadius: "14px",
                marginRight: "4px",
              }}
            />
          )}
          Tareas
        </button>
        <button
          className={
            selectedItem === "videoLecciones"
              ? styles.itemSelectedAlumnos
              : styles.itemAlumnos
          }
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "200px",
          }}
          onClick={() => {
            handleSearchClick("videoLecciones");
            setSelectedItem("videoLecciones");
            setOpenFilter(false);
          }}
        >
          {selectedItem === "videoLecciones" ? (
            <Badge
              count={totalClassroomLessons === 0 ? "00" : totalClassroomLessons} //TODO: cambiar por el total de videoLecciones
              style={{
                backgroundColor: "#3843D0",
                borderColor: "#3843D0",
                borderRadius: "14px",
                marginRight: "4px",
              }}
            />
          ) : (
            <Badge
              count={totalClassroomLessons === 0 ? "00" : totalClassroomLessons} //TODO: cambiar por el total de videoLecciones
              style={{
                backgroundColor: "#6A6F8A",
                borderColor: "#6A6F8A",
                borderRadius: "14px",
                marginRight: "4px",
              }}
            />
          )}
          Lecciones Planeadas
        </button>
        <button
          className={
            selectedItem === "calificaciones"
              ? styles.itemSelectedAlumnos
              : styles.itemAlumnos
          }
          onClick={() => {
            handleSearchClick("calificaciones");
            setSelectedItem("calificaciones");
            navigateCalificationsLog();
          }}
        >
          Calificaciones
        </button>
        <button
          className={
            selectedItem === "alumnos"
              ? styles.itemSelectedAlumnos
              : styles.itemAlumnos
          }
          onClick={() => {
            handleSearchClick("alumnos");
            setSelectedItem("alumnos");
            setOpenFilter(false);
          }}
          name="alumnos"
        >
          {selectedItem === "alumnos" ? (
            <Badge
              count={totalStudents}
              style={{
                backgroundColor: "#3843D0",
                borderColor: "#3843D0",
                borderRadius: "14px",
                marginRight: "4px",
              }}
            />
          ) : (
            <Badge
              count={totalStudents}
              style={{
                backgroundColor: "#6A6F8A",
                borderColor: "#6A6F8A",
                borderRadius: "14px",
                marginRight: "4px",
              }}
            />
          )}
          Alumnos
        </button>
      </div>

      {flags.downloadBoton && (
        <div className={styles.downloadTableAsExcel}>
          {selectedItem !== "alumnos" ? (
            <button
              className={styles.addStudentButton}
              onClick={() => {
                setIsDownloadModalOpen(true);
                teacherDownloadCalificationsLog();
              }}
            >
              Descargar
            </button>
          ) : (
            <></>
            // <button
            // 	className={styles.addStudentButton}
            // 	onClick={() => {
            // 		setAddStudentAccount(true);
            // 	}}
            // >
            // 	Añadir alumno
            // </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Filters;
