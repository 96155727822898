import React, { FC } from "react";
import styles from "./drawerNavigation.module.scss";
import cross from "../../../../../assets/images/crossNavigation.png";
import programDrawer from "../../../../../assets/icons/sider/programDrawer.png";
import englishDrawer from "../../../../../assets/icons/sider/englishDrawer.png";
import literacyDrawer from "../../../../../assets/icons/sider/literacyDrawer.png";
import literacyDrawerStudent from "../../../../../assets/icons/sider/literacyDrawerStudent.png";
import { useNavigate } from "react-router-dom";
import { getUserByEmail_getUserByEmail } from "../../../../../__generated__/gql-types/getUserByEmail";
interface DrawerProps {
  visible: boolean;
  onClose: () => void;
  userDb: getUserByEmail_getUserByEmail;
}
const DrawerNavigation: FC<DrawerProps> = ({ onClose, visible, userDb }) => {
  const navigate = useNavigate();
  const viewLiteracy = userDb?.schools[0].view_literacy;
  const viewEnglish = userDb?.schools[0].view_english;
  const teacherView2425 = userDb?.schools[0].view_2425;
  const studentViewNem = userDb?.schools[0].view_NEM_student;
  const studentView2017 = userDb?.schools[0].view_2017_student;
  const studentView2425 = userDb?.schools[0].view_2425_student;
  const studentViewReadingEnglish = userDb?.schools[0].view_reading_english;
  const studentViewReadingSpanish = userDb?.schools[0].view_reading_spanish;
  const isTeacher = userDb.role === "teacher" ? true : false;

  const closeMask = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClose();
    // e.isPropagationStopped();
  };
  const navigateButton = (route: string) => {
    navigate(route);
    onClose();
  };
  return (
    <div className={styles.containerMask} onClick={(e) => closeMask(e)}>
      <div
        className={styles.containerBody}
        onClick={(e) => e.isPropagationStopped()}
      >
        <div className={styles.containerCross}>
          <img src={cross} alt="asd" onClick={onClose} />
        </div>
        <div className={styles.containerButtons}>
          {((studentViewNem && !isTeacher) || isTeacher) && (
            <div
              className={
                isTeacher ? styles.buttonProgram : styles.buttonProgramStudent
              }
              onClick={() => navigateButton("/nem")}
            >
              <img src={programDrawer} alt="eng" />
              <div className={styles.buttonText}>
                {isTeacher ? "Programa Luca" : "CURSO LUCA"}
              </div>
            </div>
          )}
          {((studentView2017 && !isTeacher) || isTeacher) && (
            <div
              className={
                isTeacher ? styles.buttonProgram : styles.buttonProgramStudent
              }
              onClick={() => navigateButton("/2017")}
            >
              <img src={programDrawer} alt="eng" />
              <div className={styles.buttonText}>
                {isTeacher ? "Programa 2017" : "CURSO 2017"}{" "}
              </div>
            </div>
          )}

          {((!isTeacher && studentView2425) ||
            (isTeacher && teacherView2425)) && (
            <div
              className={
                isTeacher ? styles.buttonProgram : styles.buttonProgramStudent
              }
              onClick={() => navigateButton("/2425")}
            >
              <img src={literacyDrawer} alt="eng" />
              <div className={styles.buttonText}>
                {isTeacher ? "Programa 2024/2025" : "CURSO 2024/2025"}{" "}
              </div>
            </div>
          )}

          {(studentViewReadingEnglish || studentViewReadingSpanish) && (
            <div
              className={
                isTeacher ? styles.buttonProgram : styles.buttonProgramStudent
              }
              onClick={() => navigateButton("/literacy")}
            >
              <img
                src={!isTeacher ? literacyDrawerStudent : literacyDrawer}
                alt="eng"
              />
              <div className={styles.buttonText}>
                {isTeacher ? "Lectura" : "BIBLIOTECA"}
              </div>
            </div>
          )}
          {viewEnglish && isTeacher && (
            <div
              className={
                isTeacher ? styles.buttonProgram : styles.buttonProgramStudent
              }
              onClick={() => navigateButton("/english")}
            >
              <img src={englishDrawer} alt="eng" />
              <div className={styles.buttonText}> Programa Inglés</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DrawerNavigation;
