import { CSSProperties, FC, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
const style: CSSProperties = {
  borderBottom: "8px solid #ffa11d",
  backgroundColor: "#ffc95c",
  borderRadius: "15px",
  width: "180px",
  height: "115px",
  cursor: "pointer",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  padding: "8px",
  lineHeight: "1.2",
};

const droppedStyle: CSSProperties = {
  borderTop: "15px solid ##b2b3bf",
  boxShadow: "inset 0 3px 5px 0 rgba(0, 0, 0, 0.2)",
  backgroundColor: "#DADBE7",
  borderRadius: "15px",
  width: "180px",
  height: "115px",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: "0.5 !important",
};
const styleMobile: CSSProperties = {
  borderBottom: "8px solid #ffa11d",
  backgroundColor: "#ffc95c",
  borderRadius: "15px",
  width: "140px",
  height: "75px",
  cursor: "move",

  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  padding: "8px",
  lineHeight: "1.2",
};

const droppedStyleMobile: CSSProperties = {
  borderTop: "15px solid ##b2b3bf",
  boxShadow: "inset 0 3px 5px 0 rgba(0, 0, 0, 0.2)",
  backgroundColor: "#DADBE7",
  borderRadius: "15px",
  width: "140px",
  height: "75px",
  fontSize: "16px",
  cursor: "not-allowed",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: "0.5 !important",
};

export interface BoxProps {
  name: string;
  type: string;
  isDropped?: boolean;
  droppedBoxNames: any;
  id: any;
}

export const Box: FC<BoxProps> = ({ name, type, droppedBoxNames, id }) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1000); // Cambia el valor 768 según tus necesidades
  };

  useEffect(() => {
    // Llama a la función inicialmente para establecer el estado inicial
    handleResize();

    // Agrega un event listener para escuchar cambios en el tamaño de la pantalla
    window.addEventListener("resize", handleResize);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // El array vacío [] asegura que este efecto solo se ejecute una vez

  const isDropped = (boxName: string) => {
    return droppedBoxNames.indexOf(boxName) > -1;
  };
  const [{ styles, title }, drag] = useDrag(
    () => ({
      type: type.toString(),
      item: { name, id },
      collect: (monitor) => ({
        styles:
          monitor.isDragging() || isDropped(name)
            ? isMobile
              ? droppedStyleMobile
              : droppedStyle
            : isMobile
            ? styleMobile
            : style,
        title: monitor.isDragging() || isDropped(name) ? "" : name,
      }),
      canDrag: isDropped(name) ? false : true,
    }),

    [name, isDropped]
  );

  return (
    <div ref={drag} style={{ ...styles }} data-testid="box">
      <div
        style={{
          textAlign: "center",
        }}
      >
        {title}
      </div>
    </div>
  );
};
