import React, { FC, useEffect, useState } from "react";
import styles from "./carBanner.module.scss";
import bellMission from "../../../../../assets/icons/bellMission.svg";
import { useGetUser } from "../../../../../hooks/useGetUser";
import { getClassroomsByStudentIdV2_getClassroomsByStudentIdV2 } from "../../../../../__generated__/gql-types/getClassroomsByStudentIdV2";
import { getHomeworksByClassroom_getHomeworksByClassroom } from "../../../../../__generated__/gql-types/getHomeworksByClassroom";
import { useNavigate } from "react-router-dom";

interface PropsCardBanner {
  classRoom: getClassroomsByStudentIdV2_getClassroomsByStudentIdV2;
  homeworks: (getHomeworksByClassroom_getHomeworksByClassroom | null)[];
}
const CardBanner: FC<PropsCardBanner> = ({ classRoom, homeworks }) => {
  const { userDb: userToken } = useGetUser();
  const [countPendingHomework, setCountPendingHomework] = useState<number>(0);
  const navigate = useNavigate();
  useEffect(() => {
    let contador = 0;
    homeworks
      ?.filter((homework) => homework?.classroom?.id === classRoom?.id)
      .forEach((homework) => {
        if (
          (homework?.users?.length === 0 ||
            homework?.users?.find(
              (user) => Number(user.id) === Number(userToken?.id)
            )) &&
          homework.status === "inCourse" &&
          homework.finished !== true &&
          (!homework?.tries ||
            (homework?.tries &&
              homework?.tries - Number(homework?.studentTries) > 0))
        ) {
          contador++;
        }
      });
    setCountPendingHomework(contador);
  }, [classRoom, userToken?.id, homeworks]);

  const functionPromedio = () => {
    if (
      classRoom?.program?.subject?.id === "1" &&
      userToken?.score?.math_average
    ) {
      return userToken?.score?.math_average;
    }
    if (
      classRoom?.program?.subject?.id === "2" &&
      userToken?.score?.spanish_average
    ) {
      return userToken?.score?.spanish_average;
    }
    if (
      classRoom?.program?.subject?.id === "4" &&
      userToken?.score?.science_average
    ) {
      return userToken?.score?.science_average;
    }
    if (
      classRoom?.program?.subject?.id === "16" &&
      userToken?.score?.english_average
    ) {
      return userToken?.score?.english_average;
    }
    return 0;
  };
  return (
    <div
      className={styles.container}
      onClick={() => {
        navigate(`/mymissions/${classRoom.id}`);
      }}
    >
      <div className={styles.firstColumn}>
        <div className={styles.subjectText}>
          {classRoom.program?.subject?.name} {classRoom.program?.grade?.grade}°{" "}
          {classRoom.group?.name}
        </div>
        <div className={styles.subMissionsContainer}>
          <img src={bellMission} alt="Bell" />
          <h3 className={styles.missionCount}>
            {countPendingHomework}{" "}
            {countPendingHomework === 1
              ? "misión pendiente"
              : "misiones pendientes"}
          </h3>
        </div>
      </div>
      <div className={styles.secondColumn}>
        <div className={styles.quad}>
          <div className={styles.promedio}>Promedio</div>
          <span className={styles.points}>
            {functionPromedio() ? (functionPromedio() * 10) / 10 : "--"}/
            <span className={styles.smallPoints}>10</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardBanner;
