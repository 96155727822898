import { Drawer } from "antd";
import { Link } from "react-router-dom";
import styles from "./drawerHomeworkStudent.module.scss";

// Icons
import defaultImage from "../../../../assets/images/defaultImage.svg";
import cross from "../../../../assets/icons/others/close-icon.svg";
import downloader from "../../../../assets/icons/downloadIcon.svg";
import upload from "../../../../assets/icons/downloadDrawerLesson.svg";
import calendar from "../../../../assets/icons/calendar-icon.svg";
import arrow from "../../../../assets/icons/arrow_back.svg";
import moon from "../../../../assets/icons/students/moon.svg";
import marte from "../../../../assets/icons/students/marte.svg";
import { toLocalDate } from "../../../../utils/functions";
import { FC, useEffect, useState } from "react";
import { ButtonHomeworkEvidence } from "./ButtonHomeworkEvidence";
import useAuth from "../../../../context/useAuth";
import { Evidence } from "../SingleCourse/BannerCards/Card/DrawerInstructions/Evidence";
import thumbnailCiencias from "../../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../../assets/images/thumbnail/thumbnailEspañol.png";
import CoverEvaluation from "../../../../assets/images/thumbnail/CoverEvaluation.png";

import CoverWriting from "../../../../assets/images/thumbnail/CoverWriting.png";
import ensayoMatematica from "../../../../assets/images/thumbnail/ensayoMatematica.png";
import ensayoEspañol from "../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../assets/images/thumbnail/ensayoCiencias.png";

import useIsMobile from "../../../../hooks/useIsMobile";
import { ProgressHomework } from "./ProgressHomework";
import { environment } from "../../../../configuration/environment";
import UseGetHomeworkByIdForStudent from "../../../../api/useGetHomeworkByIdForStudent";
import { getHomeworkByIdForStudent_getHomeworkByIdForStudent } from "../../../../__generated__/gql-types/getHomeworkByIdForStudent";
import { Loader } from "../../../Commons";
import { DrawerInteligentStudent } from "../DrawerInteligentStudent";
interface DrawerProp {
  onCloseLesson: () => void;
  visibleLesson: boolean;
  homework_id: string | undefined;
  finishedLesson?: boolean;
}
//REVISAR ESTO CUANDO VUELVAN LOS PUNTOS EXTRA
const DrawerHomeworkStudent: FC<DrawerProp> = ({
  onCloseLesson,
  visibleLesson,
  homework_id,
  finishedLesson,
}) => {
  const isMobile = useIsMobile();
  const [panel, setPanel] = useState(finishedLesson ? "evidence" : "task");

  const { userToken } = useAuth();
  const [file, setFile] = useState<File | null>(null);
  const [isVisbleDrawer, SetIsVisbleDrawer] = useState(false);
  const getHomeworkByIdForStudent = UseGetHomeworkByIdForStudent();
  const [homework, setHomework] =
    useState<getHomeworkByIdForStudent_getHomeworkByIdForStudent | null>(null);

  const maxResult = Math.max(
    homework?.result ? homework.result : -1,
    homework?.post_homework_result ? homework.post_homework_result : -1
  );

  const functionReturnImg = () => {
    if (
      homework?.lessons &&
      homework?.lessons?.length >= 0 &&
      homework?.lessons[0].lesson_type === "redaction"
    ) {
      if (homework.lessons[0].subject_id === 1) {
        return ensayoMatematica;
      }
      if (
        homework.lessons[0].subject_id === 2 ||
        (homework?.lessons && homework?.lessons[0].subject_id === 17)
      ) {
        return ensayoEspañol;
      }
      if (homework.lessons[0].subject_id === 4) {
        return ensayoCiencias;
      }
      if (homework.lessons[0].subject_id === 16) {
        return CoverWriting;
      }
    }
    if (
      homework?.lessons &&
      homework?.lessons?.length >= 0 &&
      homework?.lessons[0].lesson_type === "evaluation"
    ) {
      if (homework.lessons[0].subject_id === 1) {
        return thumbnailMate;
      }
      if (homework.lessons[0].subject_id === 4) {
        return thumbnailCiencias;
      }
      if (
        homework.lessons[0].subject_id === 2 ||
        (homework?.lessons && homework?.lessons[0].subject_id === 17)
      ) {
        return thumbnailEspañol;
      }
      if (homework.lessons[0].subject_id === 16) {
        return CoverEvaluation;
      }
    }
    if (
      homework?.lessons &&
      homework?.lessons.length &&
      homework?.lessons[0]?.lesson_content?.videos?.length &&
      homework?.lessons[0]?.lesson_content?.videos[0]?.imageUrl &&
      homework?.lessons[0].lesson_type === "videolesson"
    ) {
      return homework?.lessons[0]?.lesson_content?.videos[0].imageUrl;
    }
    if (
      homework?.lessons &&
      homework?.lessons.length &&
      homework?.lessons[0]?.lesson_content?.literacy &&
      homework?.lessons[0]?.lesson_content?.literacy?.cover
    ) {
      return homework?.lessons[0]?.lesson_content?.literacy?.cover;
    }
    if (
      homework?.lessons &&
      homework?.lessons.length &&
      homework?.lessons[0]?.lesson_content?.aiconversations?.card_image
    ) {
      return homework?.lessons[0]?.lesson_content.aiconversations?.card_image;
    }

    return defaultImage;
  };

  const resolveDownload = () => {
    return homework?.teacher_file_url !== null
      ? `${environment.awsBucketVideos}${homework?.teacher_file_url}`
      : "";
  };

  useEffect(() => {
    if (homework?.lessons && homework.lessons.length > 0) {
      const lessonId = homework.lessons[0].lesson_content?.id;
      if (lessonId && userToken?.id) {
        const storageKey = `${userToken?.id}-${lessonId}`;
        const getLocal = localStorage.getItem(storageKey);

        if (getLocal) {
          try {
            const parsedData = JSON.parse(getLocal);
            if (parsedData && parsedData.file) {
              setFile(parsedData.file);
            } else {
              console.log("esta mal el objeto");
              // Manejo si no se encuentra la propiedad 'file' en los datos parseados.
            }
          } catch (error) {
            // Manejo si ocurre un error al analizar la cadena JSON.
            console.error("Error al analizar JSON:", error);
          }
        } else {
          // Manejo si no se encuentra el valor en el almacenamiento local.
        }
      }
    }
  }, [homework, userToken?.id]);

  useEffect(() => {
    (async () => {
      if (homework_id) {
        const response = await getHomeworkByIdForStudent({
          homeworkId: homework_id,
        });
        console.log(response);
        response && setHomework(response);
      }
    })();
  }, [homework_id, getHomeworkByIdForStudent]);

  return (
    <>
      <Drawer
        closable={false}
        placement={isMobile ? "bottom" : "right"}
        className={finishedLesson ? "estilosIntruc" : ""}
        styles={{
          mask: finishedLesson
            ? {
                width: "100%",
                left: 0,
              }
            : {},
        }}
        onClose={() => {
          onCloseLesson();
        }}
        open={visibleLesson}
        title={
          homework && (
            <div className={styles.lessonDetailContainer}>
              <div className={styles.foto}>
                <img src={functionReturnImg()} alt="default" />
              </div>

              <div className={styles.bandera}>
                <div className={styles.closeHeader}>
                  <img
                    src={
                      finishedLesson ? cross : panel === "task" ? cross : arrow
                    }
                    onClick={() => {
                      finishedLesson
                        ? onCloseLesson()
                        : panel === "task"
                        ? onCloseLesson()
                        : setPanel("task");
                    }}
                    alt="cross"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className={styles.titleContainer}>
                  <span className={styles.textTitle}>
                    {homework?.lessons && homework.lessons[0]?.title}
                  </span>
                  <p>{homework?.lessons && homework.lessons[0]?.description}</p>
                </div>
              </div>
            </div>
          )
        }
        footer={
          homework && (
            <div className={styles.footerDetail}>
              <div className={styles.containerImage}>
                <div className={styles.containerImageEvidence}>
                  <img src={moon} alt="" />
                  <img src={marte} alt="" />
                </div>
                {panel === "evidence" && !homework?.studentEvidenceEvent && (
                  <div className={styles.textEvidence}>
                    Adjunta una evidencia de la actividad requerida por tu
                    profesor.{" "}
                  </div>
                )}
              </div>
              {panel === "evidence" && (
                <ButtonHomeworkEvidence
                  onCloseLesson={onCloseLesson}
                  homework={homework ?? null}
                  file={file}
                  setFile={setFile}
                />
              )}
              {panel === "task" && (
                <div className={styles.containerButtons}>
                  {homework?.needs_file_evidence &&
                    !homework.studentEvidenceEvent &&
                    homework.status !== "finished" && (
                      <button
                        className={styles.taskButtonDelete}
                        onClick={() => setPanel("evidence")}
                      >
                        ADJUNTAR EVIDENCIA
                        <img src={upload} alt="downloader" />
                      </button>
                    )}
                  {homework?.needs_file_evidence &&
                    homework.studentEvidenceEvent && (
                      <button
                        className={styles.taskButtonDelete}
                        onClick={() => setPanel("evidence")}
                      >
                        VER EVIDENCIA
                        <img src={downloader} alt="downloader" />
                      </button>
                    )}
                  {homework?.needs_file_evidence &&
                    homework.status === "finished" &&
                    !homework.studentEvidenceEvent && (
                      <button className={styles.taskButtonNotEvidence}>
                        NO HAY EVIDENCIA
                        <img src={downloader} alt="downloader" />
                      </button>
                    )}

                  {homework.lessons &&
                  homework?.lessons[0].lesson_type === "redaction" ? (
                    homework.cta_button === "COMENZAR" ? (
                      <Link
                        className={styles.taskButtonMision}
                        to={`/task/${
                          homework?.lessons && homework.lessons[0]?.id
                        }?homework=${homework?.id}`}
                        state={{
                          homework,
                          origin: "homework",
                          from: "homework",
                          onNavigate: true,
                        }}
                      >
                        COMENZAR
                      </Link>
                    ) : homework.cta_button === "TERMINAR" ? (
                      <Link
                        className={styles.taskButtonMision}
                        to={`/task/${
                          homework?.lessons && homework.lessons[0]?.id
                        }?homework=${homework?.id}`}
                        state={{
                          homework,
                          from: "homework",
                          origin: "homework",
                          onNavigate: true,
                        }}
                      >
                        TERMINAR
                      </Link>
                    ) : (
                      <div
                        className={styles.taskButtonMision}
                        onClick={() => {
                          SetIsVisbleDrawer(true);
                        }}
                      >
                        {homework.cta_button === "TERMINAREVIDENCIA"
                          ? "REVISAR"
                          : homework.cta_button}
                      </div>
                    )
                  ) : homework.lessons &&
                    homework?.lessons[0].lesson_type === "conversation" ? (
                    <Link
                      className={styles.taskButtonMision}
                      to={`/task/${
                        homework?.lessons && homework.lessons[0]?.id
                      }?homework=${homework?.id}`}
                      state={{
                        homework,
                        from: "homework",
                        origin: "homework",
                        onNavigate: true,
                      }}
                    >
                      {homework?.result === null ? "START" : "REPEAT"}
                    </Link>
                  ) : (
                    <Link
                      className={styles.taskButtonMision}
                      to={`/task/${
                        homework?.lessons && homework.lessons[0]?.id
                      }?homework=${homework?.id}`}
                      state={{
                        homework,
                        from: "homework",
                        origin: "homework",
                        onNavigate: true,
                      }}
                    >
                      {!homework?.needs_file_evidence
                        ? homework?.result === null
                          ? "COMENZAR"
                          : "REPETIR"
                        : homework?.result === null
                        ? homework?.studentEvidenceEvent
                          ? "CONTINUAR"
                          : "COMENZAR"
                        : homework?.studentEvidenceEvent
                        ? "REPETIR"
                        : "CONTINUAR"}
                    </Link>
                  )}
                </div>
              )}
            </div>
          )
        }
      >
        {!homework ? (
          <Loader />
        ) : (
          <div className={styles.container}>
            {panel === "task" && (
              <>
                <div className={styles.textInstrucciones}>INSTRUCCIONES</div>
                <div className={styles.descriptionLesson}>
                  <div className={styles.containerBadge}>
                    {homework?.status === "finished" ? (
                      <div className={styles.containerFinished}>
                        <div className={styles.tagsGradeWithOutTry}>
                          <img src={calendar} alt="img" />
                          Finalizada
                        </div>
                        {!(
                          homework.lessons &&
                          homework.lessons[0].lesson_type === "redaction"
                        ) && (
                          <div className={styles.textRequiereEvidence}>
                            El plazo de entrega de la tarea ha finalizado.
                            Puedes volver a hacerla las veces que quieras para
                            conseguir todos los cristales, pero el resultado no
                            impactará en tu promedio.
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className={styles.tagsGrade}>
                          <img src={calendar} alt="" />
                          Finaliza {homework && toLocalDate(homework.end_date)}
                        </div>

                        {homework?.tries &&
                        homework.studentTries &&
                        (homework.tries - Number(homework.studentTries) === 0 ||
                          homework.tries - Number(homework.studentTries) <
                            0) ? (
                          <div className={styles.tagsGradeWithOutTry}>
                            No te quedan intentos
                          </div>
                        ) : (
                          homework?.tries && (
                            <div className={styles.tagsGrade}>
                              Te quedan{" "}
                              {homework?.tries - Number(homework.studentTries)}{" "}
                              intentos
                            </div>
                          )
                        )}
                      </>
                    )}
                  </div>

                  <div className={styles.containerRequiereEvidence}>
                    {homework?.status !== "finished" &&
                      homework?.tries &&
                      homework.studentTries &&
                      homework.tries - Number(homework.studentTries) === 0 && (
                        <div className={styles.textRequiereEvidence}>
                          No te quedan intentos. Puedes volver a realizar la
                          misión las veces que quieras, pero el resultado no
                          impactará en tu promedio.
                        </div>
                      )}
                    {homework?.description && (
                      <div className={styles.textRequiereEvidence}>
                        {homework?.description}
                      </div>
                    )}
                    {homework?.needs_file_evidence && (
                      <div>
                        <div className={styles.titleRequiereEvidence}>
                          Requiere evidencia
                        </div>
                        <div className={styles.textRequiereEvidence}>
                          Adjunta el archivo que solicita tu profesor para
                          completar la tarea
                        </div>
                      </div>
                    )}
                    {homework?.teacher_file_url &&
                      homework?.status !== "finished" && (
                        <div>
                          <div className={styles.textRequiereEvidence}>
                            Descarga el documento que te ha enviado tu profesor.
                          </div>

                          <div className={styles.link}>
                            <a
                              href={resolveDownload()}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>Descargar archivo</span>
                            </a>
                            <img src={downloader} alt="" />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <ProgressHomework
                  resultHomework={maxResult !== -1 ? maxResult : 0}
                ></ProgressHomework>
              </>
            )}

            {panel === "evidence" && <Evidence file={file} setFile={setFile} />}
          </div>
        )}
      </Drawer>
      {isVisbleDrawer && (
        <DrawerInteligentStudent
          onCloseDrawer={onCloseLesson}
          homework={homework}
          visibleDrawer={isVisbleDrawer}
        />
      )}
    </>
  );
};

export default DrawerHomeworkStudent;
