import React, { FC } from "react";
import styles from "./headerMobile.module.scss";
import arrowback from "../../../../assets/icons/arrow_back.svg";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  title: string;
  openCourse?: boolean;
  setOpenDrawer?: (e: boolean) => void;
  dataClassroom?: any;
}

const HeaderMobile: FC<HeaderProps> = ({
  title,
  dataClassroom,
  openCourse,
  setOpenDrawer,
}) => {
  const navigate = useNavigate();

  const onOpenClass = () => {
    setOpenDrawer ? setOpenDrawer(false) : navigate("/mymissions");
  };

  return (
    <div className={styles.container}>
      {!dataClassroom?.name && (
        <div className={styles.headerMobileContainer}>
          {openCourse && (
            <img src={arrowback} alt="arrow back" onClick={onOpenClass} />
          )}
          <div className={styles.titleHeader}>{title}</div>
        </div>
      )}
      {dataClassroom?.name && (
        <div className={styles.headerMobileContainer}>
          <img src={arrowback} alt="arrow back" onClick={onOpenClass} />
          <div className={styles.titleHeader}>{title}</div>
        </div>
      )}
    </div>
  );
};

export default HeaderMobile;
