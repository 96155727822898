import React from "react";
import styles from "./viewTablet.module.scss";
import logoLucaTablet from "../../../assets/images/tablet/logoLucaTablet.png";
import logoBotTablet from "../../../assets/images/tablet/logoBotTablet.png";
import verticalMobile from "../../../assets/images/tablet/verticalMobile.png";

const ViewTablet = () => {
  return (
    <div className={styles.container}>
      <div className={styles.containerLogo}>
        <img src={logoLucaTablet} alt="img" />
      </div>
      <div className={styles.content}>
        <img src={logoBotTablet} alt="img" className={styles.logoBot} />
        <div className={styles.text}>
          La plataforma Luca ha sido diseñada para su uso en formato horizontal,
          esto nos permite brindar mejores características sin afectar la
          experiencia.
          <br></br>
          <br></br>
          Gira tu tablet para poder disfrutar de todas las funcionalidades de la
          plataforma.
        </div>
        <img src={verticalMobile} alt="img" className={styles.verticalMobile} />
      </div>
    </div>
  );
};

export default ViewTablet;
