import React, { FC, useState } from "react";
import styles from "./quizHeader.module.scss";
import { Tabs, Popover, Checkbox } from "antd";
import botonSoundOn from "../../../../../assets/icons/botonSoundOn.svg";
import botonSoundOff from "../../../../../assets/icons/botonSoundOff.svg";
import instructions from "../../../../../assets/icons/students/instructions.svg";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import { ValidationProgress } from "../ValidationProgress";
import { useFlags } from "launchdarkly-react-client-sdk";
import cross from "../../../../../assets/images/cross.svg";
import { ModalConfirmation } from "../../../../Shared/ModalConfirmation";
import { useLocation, useNavigate } from "react-router-dom";
interface QuizHeaderProps {
  individualLesson: getLessonById_getLessonById | null;
  handleInstructions: () => void;
  contProgress: number;
  total: number;
  setSelectedKeyLiteracy?: React.Dispatch<React.SetStateAction<string>>;
  selectedKeyLiteracy: string;
  qualitySelected?: number;
  setQualitySelected?: React.Dispatch<React.SetStateAction<number>>;
  isVerticalMobile?: boolean;
  sound?: boolean;
  setSound?: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuizHeader: FC<QuizHeaderProps> = ({
  individualLesson,
  total,
  contProgress,
  selectedKeyLiteracy,
  qualitySelected,
  isVerticalMobile,
  setQualitySelected,
  handleInstructions,
  setSelectedKeyLiteracy,
  sound,
  setSound,
}) => {
  const flags = useFlags();
  const qualityFlag = flags.quality;
  const [open, setOpen] = useState(false);
  const onCloseModalConfirmation = () => {
    setOpen(false);
  };
  let navigate = useNavigate();
  const location = useLocation();
  const handleRedirection = () => {
    if (location?.state?.from) {
      return navigate(-1);
    } else {
      return navigate("/mymissions");
    }
  };
  const content = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "7%",
      }}
    >
      <Checkbox
        className={styles.styleCheck}
        checked={qualitySelected === 1080}
        onChange={() => setQualitySelected && setQualitySelected(1080)}
      >
        1080p
      </Checkbox>
      <Checkbox
        className={styles.styleCheck}
        checked={qualitySelected === 720}
        onChange={() => setQualitySelected && setQualitySelected(720)}
      >
        720p
      </Checkbox>
      <Checkbox
        className={styles.styleCheck}
        checked={qualitySelected === 360}
        onChange={() => setQualitySelected && setQualitySelected(360)}
      >
        360p
      </Checkbox>
    </div>
  );

  const handleChangeSelectedKeyLiteracy = (values: string) => {
    console.log(values);
    setSelectedKeyLiteracy && setSelectedKeyLiteracy(values);
  };

  return (
    <>
      <div
        className={
          individualLesson?.lesson_type === "literacy"
            ? styles.headerTopLiteracy
            : styles.headerTop
        }
      >
        <span
          style={{
            cursor: "pointer",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "16px",
          }}
        >
          {individualLesson?.title && individualLesson?.title?.length > 50
            ? individualLesson?.title?.slice(0, 50) + " ..."
            : individualLesson?.title}
        </span>
        {individualLesson?.lesson_type === "literacy" && (
          <div className={styles.tabInitialStyle}>
            <Tabs
              defaultActiveKey="1"
              activeKey={selectedKeyLiteracy}
              centered={true}
              tabBarGutter={30}
              onChange={handleChangeSelectedKeyLiteracy}
              className={styles.tabStyleContainer}
            >
              <Tabs.TabPane
                tab={individualLesson?.subject_id === 16 ? "READ " : "LEER"}
                key="1"
                className={styles.tabText}
              />
              <Tabs.TabPane
                tab={
                  individualLesson?.subject_id === 16 ? "ANSWER " : "RESPONDER"
                }
                key="2"
                className={styles.tabText}
              />
            </Tabs>
          </div>
        )}
        <div className={styles.rightItems}>
          {qualityFlag && individualLesson?.lesson_type === "videolesson" && (
            <Popover
              content={content}
              trigger="click"
              className={styles.popoverContent}
            >
              <p>Calidad video</p>
            </Popover>
          )}

          <button
            onClick={() => {
              handleInstructions();
            }}
          >
            <img src={instructions} alt="instructions" />
          </button>
          {!(
            individualLesson?.lesson_type === "literacy" &&
            selectedKeyLiteracy === "1"
          ) && (
            <>
              {sound ? (
                <div
                  onClick={() => {
                    setSound && setSound(!sound);
                  }}
                >
                  <img src={botonSoundOn} alt="instructions" />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setSound && setSound(!sound);
                  }}
                >
                  <img src={botonSoundOff} alt="instructions" />
                </div>
              )}
            </>
          )}
          {individualLesson?.lesson_type === "literacy" && (
            <div className={styles.cross} onClick={() => setOpen(true)}>
              <img src={cross} alt="" />
            </div>
          )}
        </div>
      </div>
      <div
        className={
          individualLesson?.lesson_type === "literacy" &&
          selectedKeyLiteracy === "1"
            ? styles.literacyProgressHeader
            : styles.header
        }
      >
        <ValidationProgress
          individualLesson={individualLesson}
          selectedKeyLiteracy={selectedKeyLiteracy}
          total={total}
          contProgress={contProgress}
          handleInstructions={handleInstructions}
        />
      </div>
      {flags.quality &&
        isVerticalMobile &&
        individualLesson?.lesson_type === "videolesson" && (
          <div className={styles.qualityMobileContainer}>
            <p className={styles.qualityTitle}>Calidad video</p>
            <Checkbox
              className={styles.styleCheck}
              checked={qualitySelected === 1080}
              onChange={() => setQualitySelected && setQualitySelected(1080)}
            >
              1080p
            </Checkbox>
            <Checkbox
              className={styles.styleCheck}
              checked={qualitySelected === 720}
              onChange={() => setQualitySelected && setQualitySelected(720)}
            >
              720p
            </Checkbox>
            <Checkbox
              className={styles.styleCheck}
              checked={qualitySelected === 360}
              onChange={() => setQualitySelected && setQualitySelected(360)}
            >
              360p
            </Checkbox>
          </div>
        )}
      {individualLesson?.lesson_type === "literacy" &&
        selectedKeyLiteracy === "1" && (
          <div className={styles.titleLiteracy}>{individualLesson?.title}</div>
        )}
      <ModalConfirmation
        open={open}
        onCloseModalConfirmation={onCloseModalConfirmation}
        handleRedirection={handleRedirection}
      />
    </>
  );
};
export default QuizHeader;
