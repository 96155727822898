import thumbnailClase from "../../../../assets/images/thumbnail/thumbnailOrientacionDidactica.png";
import thumbnailClaseEnglish from "../../../../assets/images/thumbnail/teacherGuide.png";
import thumbnailDynamics from "../../../../assets/images/thumbnail/thumbnailDynamics.png";
import thumbnailDiapositivasEnglish from "../../../../assets/images/thumbnail/slidesEnglish.png";
import thumbnailDiapositivas from "../../../../assets/images/thumbnail/thumbnailDiapositivas.png";
import thumbnailListening from "../../../../assets/images/thumbnail/thumbnailAudio.png";
import thumbnailBookPages from "../../../../assets/images/thumbnail/thumbnailBookPages.png";
import thumbnailBookPagesEnglish from "../../../../assets/images/thumbnail/bookEnglish.png";
import thumbnailCiencias from "../../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../../assets/images/thumbnail/thumbnailEspañol.png";
import ensayoMatematica from "../../../../assets/images/thumbnail/ensayoMatematica.png";
import examenSpain from "../../../../assets/images/thumbnail/coverExamSpain.png";
import ensayoEspañol from "../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../assets/images/thumbnail/ensayoCiencias.png";
import listeningExam from "../../../../assets/images/thumbnail/listening_exam.png";
import writingExam from "../../../../assets/images/thumbnail/writing_exam.png";
import defaultImage from "../../../../assets/images/defaultImage.svg";
import CoverWriting from "../../../../assets/images/thumbnail/CoverWriting.png";
import CoverEvaluation from "../../../../assets/images/thumbnail/CoverEvaluation.png";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";

export const getLessonImage = (lesson: getLessonById_getLessonById | null) => {
  if (
    lesson?.lesson_content?.videos?.length &&
    lesson.lesson_content.videos[0]?.imageUrl
  ) {
    return lesson.lesson_content.videos[0].imageUrl;
  }

  if (
    lesson?.lesson_type === "literacy" &&
    lesson.lesson_content?.literacy?.cover
  ) {
    return lesson.lesson_content.literacy.cover;
  }

  switch (lesson?.lesson_type) {
    case "classroomguide":
      switch (lesson.subject_id) {
        case 16:
          return thumbnailClaseEnglish;
        default:
          break;
      }
      return thumbnailClase;
    case "manipulative_dynamics":
      return thumbnailDynamics;
    case "audio":
      return thumbnailListening;
    case "book_pages":
      switch (lesson.subject_id) {
        case 16:
          return thumbnailBookPagesEnglish;
        default:
          break;
      }
      return thumbnailBookPages;
    case "slides":
      switch (lesson.subject_id) {
        case 16:
          return thumbnailDiapositivasEnglish;
        default:
          break;
      }
      return thumbnailDiapositivas;
    case "evaluation":
      switch (lesson.subject_id) {
        case 1:
          return thumbnailMate;
        case 4:
          return thumbnailCiencias;
        case 2:
        case 17:
          return thumbnailEspañol;
        case 16:
          return CoverEvaluation;
        default:
          break;
      }
      break;
    case "redaction":
      switch (lesson.subject_id) {
        case 1:
          return ensayoMatematica;
        case 4:
          return ensayoCiencias;
        case 2:
        case 17:
          return ensayoEspañol;
        case 16:
          return CoverWriting;
        default:
          break;
      }
      break;
    case "conversation":
      return lesson.lesson_content?.aiconversations?.card_image || defaultImage;
    case "audio_exam":
      return listeningExam;
    case "writing_exam":
      return lesson.subject?.id === "16" ? writingExam : examenSpain;
    default:
      return defaultImage;
  }

  return defaultImage;
};
