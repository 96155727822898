import React, { FC } from "react";
import styles from "./mainInteligentStudent.module.scss";
import logoRubicas from "../../../../../assets/icons/logoRubicas.png";
import bigLogoRubicas from "../../../../../assets/icons/bigLogoRubicas.png";
import { Input, Tabs } from "antd";
import { EditorComponent } from "../../../../Shared/EditorComponent";
import { getRedactionCorrection_getRedactionCorrection } from "../../../../../__generated__/gql-types/getRedactionCorrection";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
const { TabPane } = Tabs;
const { TextArea } = Input;

interface PropsMain {
  evaluateStudent: getRedactionCorrection_getRedactionCorrection | null;
  individualLesson: getLessonById_getLessonById | null;
}

const MainInteligentTeacher: FC<PropsMain> = ({
  individualLesson,
  evaluateStudent,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerInfo}>
        <div className={styles.responseStudent}>
          <Tabs defaultActiveKey="1" centered className={styles.tabStyles}>
            <TabPane tab="Tu respuesta" key="1">
              {evaluateStudent?.redaction_response && (
                <EditorComponent
                  text={evaluateStudent.redaction_gpt_response_correction ?? ""}
                  readOnly={true}
                />
              )}
            </TabPane>
            {individualLesson?.lesson_content?.redaction?.question && (
              <TabPane tab="Tarea" key="2">
                <div className={styles.containerEditor}>
                  <EditorComponent
                    text={individualLesson?.lesson_content?.redaction?.question}
                    readOnly={true}
                  />
                </div>
              </TabPane>
            )}
          </Tabs>
        </div>
        <div className={styles.qualificationStudent}>
          <div className={styles.containerQualificationStudent}>
            <div>Calificación</div>
            {evaluateStudent?.note && (
              <input
                className={styles.boxQualification}
                type="number"
                disabled={true}
                value={evaluateStudent?.note ?? 1}
              />
            )}
          </div>
          <div className={styles.containerRubricas}>
            {!evaluateStudent?.note ? (
              <div className={styles.containerWithOutNote}>
                <img
                  src={bigLogoRubicas}
                  alt=""
                  className={styles.imgWithOutNote}
                />
                <div className={styles.titleWithOutNote}>
                  Tu tarea está siendo revisada. ¡Te avisaremos en la pantalla
                  principal cuando esté lista!{" "}
                </div>
              </div>
            ) : (
              <>
                <div className={styles.containerColumns}>
                  <div className={styles.firstColumn}>
                    {evaluateStudent?.rubrics?.map((rubric, index) => {
                      return (
                        <div key={index} className={styles.containerFakeInput}>
                          <div className={styles.subtitleRubricas}>
                            {rubric.rubric}
                          </div>

                          <div className={styles.boxFakeInput}>
                            {rubric.calification === 1
                              ? "Excelente"
                              : rubric.calification === 2
                              ? "Bien"
                              : rubric.calification === 3
                              ? "Regular"
                              : "Por mejorar"}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.secondColumn}>
                    <img src={logoRubicas} alt="" />
                  </div>
                </div>
                <div className={styles.commentsRubricas}>Comentarios</div>
                <TextArea
                  value={evaluateStudent?.redaction_comments ?? ""}
                  style={{
                    width: "100%",
                    height: 120,
                    resize: "none",
                    borderRadius: 8,
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainInteligentTeacher;
