import { FC } from "react";
import { Collapse, DatePicker } from "antd";
import styles from "./studentCalificationFilters.module.scss";
import finished from "../../../../assets/images/finished.png";
import inCourse from "../../../../assets/images/inCourse.png";
import programmed from "../../../../assets/images/programmed.png";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import dayjs from "dayjs";
interface ActiveFiltersProps {
  selectedStatusHomework?: any;
  selectedFromDate?: any;
  setSelectedFromDate?: any;
  selectedToDate?: any;
  setSelectedToDate?: any;
  getCalificationsData?: any;
  individualClassroom?: any;
}

const StudentCalificationsFilter: FC<ActiveFiltersProps> = ({
  selectedStatusHomework,
  selectedFromDate,
  setSelectedFromDate,
  selectedToDate,
  setSelectedToDate,
  getCalificationsData,
  individualClassroom,
}) => {
  const getStringDate = (date: dayjs.Dayjs | null | undefined) => {
    let myDate: String = dayjs(date).format("YYYY-MM-DD");
    let finalDate = `${myDate}T00:00:00Z`;
    return finalDate;
  };

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filterContent}>
        <Collapse
          defaultActiveKey={["1"]}
          ghost={true}
          expandIconPosition="right"
        >
          <CollapsePanel key={""} header={"Fecha"}>
            <div className={styles.bubblesContainer}>
              <h1>Desde (Inclusive)</h1>
              <DatePicker
                className={styles.bubbleSelected}
                onChange={(date) => {
                  if (date !== null) {
                    // setDateToShow(date);
                    setSelectedFromDate(getStringDate(date));
                  }
                }}
                format={"DD-MM-YYYY"}
              ></DatePicker>
              <h1>Hasta (Inclusive)</h1>
              <DatePicker
                className={styles.bubbleSelected}
                onChange={(date) => {
                  if (date !== null) {
                    setSelectedToDate(getStringDate(date));
                  }
                }}
                format={"DD-MM-YYYY"}
              ></DatePicker>
            </div>
          </CollapsePanel>
          <CollapsePanel key={"1"} header={"Estado Tarea"}>
            <div className={styles.bubblesContainer}>
              <button
                className={
                  selectedStatusHomework.includes("inCourse")
                    ? styles.bubbleSelected
                    : styles.bubble
                }
                name="inCourse"
              >
                En Curso
                <img src={inCourse} alt="InCourse" />
              </button>
              <button
                className={
                  selectedStatusHomework.includes("programed")
                    ? styles.bubbleSelected
                    : styles.bubble
                }
                name="programed"
              >
                Programadas
                <img src={programmed} />
              </button>
              <button
                className={
                  selectedStatusHomework.includes("finished")
                    ? styles.bubbleSelected
                    : styles.bubble
                }
                name="finished"
              >
                Finalizadas
                <img src={finished} alt="img" />
              </button>
            </div>
          </CollapsePanel>
        </Collapse>
      </div>
      <div className={styles.addFiltersButtonContainer}>
        <button
          className={styles.addFiltersButton}
          onClick={() => {
            (async () => {
              getCalificationsData(
                Number(individualClassroom?.classroom?.id),
                selectedStatusHomework,
                selectedFromDate,
                selectedToDate
              );
            })();
          }}
        >
          Aplicar Filtros
        </button>
      </div>
    </div>
  );
};

export default StudentCalificationsFilter;
