import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getResponsesByHomeworkAndUserV2,
  getResponsesByHomeworkAndUserV2Variables,
} from "../__generated__/gql-types/getResponsesByHomeworkAndUserV2";

const UseGetResponseByHomeworkAndUserV2 = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getResponsesByHomeworkAndUserV2Variables) => {
      const result: ApolloQueryResult<getResponsesByHomeworkAndUserV2> =
        await client.query({
          query: GET_RESPONSES_BY_USER,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getResponsesByHomeworkAndUserV2;
    },
    [client]
  );
};

export default UseGetResponseByHomeworkAndUserV2;

const GET_RESPONSES_BY_USER = gql`
  query getResponsesByHomeworkAndUserV2($userId: Int, $homeworkId: Int) {
    getResponsesByHomeworkAndUserV2(
      user_id: $userId
      homework_id: $homeworkId
    ) {
      id
      question
      image
      image_aspect
      type_id
      order
      url
      created_at
      updated_at
      deleted_at
      paragraph
      answers {
        id
        answer
        student_response
        selected
        image
        AI_evaluation
        image_aspect
        correct
        order
        question_id
        url
        created_at
        updated_at
        deleted_at
      }
      processedBlocks {
        block1 {
          image
          text
          coincidence
          url
        }
        block2 {
          image
          text
          coincidence
          url
        }
        blockContainer {
          image
          text
          order
          coincidence
          totalItems
          url
        }
        blockItem {
          image
          text
          coincidence
          url
        }
      }
    }
  }
`;
