import { CSSProperties, FC, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import styleScss from "./matchingText.module.scss";
import { ModalImg } from "../NewQuestions/ModalImg";

const style: CSSProperties = {
  borderTop: "15px solid ##b2b3bf",
  boxShadow: "inset 0 3px 5px 0 rgba(0, 0, 0, 0.2)",
  backgroundColor: "#B6DBFE",
  borderRadius: "15px",
  width: "180px",
  height: "115px",
  cursor: "pointer",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  objectFit: "contain",
};

const droppedStyle: CSSProperties = {
  borderBottom: "8px solid #ffa11d",
  backgroundColor: "#ffc95c",
  borderRadius: "15px",
  width: "180px",
  height: "115px",
  cursor: "not-allowed",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  padding: "8px",
  lineHeight: "1.2",
  textAlign: "center",
};

const wrongDroppedStyle: CSSProperties = {
  backgroundColor: "#FF8283",
  borderRadius: "15px",
  width: "180px",
  height: "115px",
  cursor: "not-allowed",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  padding: "8px",
  lineHeight: "1.2",
  textAlign: "center",
};
const rightDroppedStyle: CSSProperties = {
  backgroundColor: "#8DCE4F",
  borderRadius: "15px",
  width: "180px",
  height: "115px",
  cursor: "not-allowed",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  padding: "8px",
  lineHeight: "1.2",
  textAlign: "center",
};
const styleMobile: CSSProperties = {
  borderTop: "15px solid ##b2b3bf",
  boxShadow: "inset 0 3px 5px 0 rgba(0, 0, 0, 0.2)",
  backgroundColor: "#B6DBFE",
  borderRadius: "15px",
  width: "140px",
  height: "89px",
  cursor: "not-allowed",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  objectFit: "contain",
};

const droppedStyleMobile: CSSProperties = {
  borderBottom: "8px solid #ffa11d",
  backgroundColor: "#ffc95c",
  borderRadius: "15px",
  width: "140px",
  height: "89px",
  cursor: "not-allowed",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  padding: "8px",
  lineHeight: "1.2",
  textAlign: "center",
};

const wrongDroppedStyleMobile: CSSProperties = {
  backgroundColor: "#FF8283",
  borderRadius: "15px",
  width: "140px",
  height: "89px",
  cursor: "not-allowed",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  padding: "8px",
  lineHeight: "1.2",
  textAlign: "center",
};
const rightDroppedStyleMobile: CSSProperties = {
  backgroundColor: "#8DCE4F",
  borderRadius: "15px",
  width: "140px",
  height: "89px",
  cursor: "not-allowed",
  float: "left",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  padding: "8px",
  lineHeight: "1.2",
  textAlign: "center",
};
export interface DustbinProps {
  accept: string[];
  lastDroppedItem?: any;
  onDrop: (item: any) => void;
  itemsMatched: any;
  name?: string;
  img?: any;
  onCheckPressed: any;
  dustbinType: number;
}

export const Dustbin: FC<DustbinProps> = ({
  accept,
  lastDroppedItem,
  onDrop,
  name,
  img,
  itemsMatched,
  onCheckPressed,
  dustbinType,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1000); // Cambia el valor 768 según tus necesidades
  };

  useEffect(() => {
    // Llama a la función inicialmente para establecer el estado inicial
    handleResize();

    // Agrega un event listener para escuchar cambios en el tamaño de la pantalla
    window.addEventListener("resize", handleResize);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // El array vacío [] asegura que este efecto solo se ejecute una vez
  const [open, setOpen] = useState<boolean>(false);
  const [{ styles, title }, drop] = useDrop({
    accept: ["1", "2", "3", "4"],
    drop: onDrop,
    collect: (monitor) => ({
      styles: isMobile
        ? lastDroppedItem !== null && lastDroppedItem.name && !onCheckPressed
          ? droppedStyleMobile
          : lastDroppedItem !== null &&
            onCheckPressed &&
            lastDroppedItem.id !== dustbinType
          ? wrongDroppedStyleMobile
          : lastDroppedItem !== null &&
            onCheckPressed &&
            lastDroppedItem.id === dustbinType
          ? rightDroppedStyleMobile
          : styleMobile
        : lastDroppedItem !== null && lastDroppedItem.name && !onCheckPressed
        ? droppedStyle
        : lastDroppedItem !== null &&
          onCheckPressed &&
          lastDroppedItem.id !== dustbinType
        ? wrongDroppedStyle
        : lastDroppedItem !== null &&
          onCheckPressed &&
          lastDroppedItem.id === dustbinType
        ? rightDroppedStyle
        : style,
      title:
        lastDroppedItem !== null && lastDroppedItem.name
          ? lastDroppedItem.name
          : name,
      variable: lastDroppedItem ? "" : "variable",
      isCorrect: monitor.getDropResult(),
    }),
  });

  return (
    <div ref={drop} style={{ ...styles }}>
      {lastDroppedItem ? (
        `${title}`
      ) : img?.length > 0 ? (
        <img
          onClick={() => {
            setOpen(true);
          }}
          className={styleScss.imgWithOutHover}
          src={img}
          alt="default"
        ></img>
      ) : (
        `${title}`
      )}
      <ModalImg open={open} setOpen={setOpen} urlImg={img ?? ""}></ModalImg>
    </div>
  );
};
