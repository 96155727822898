import React, { FC } from "react";
import { getLessonById_getLessonById_lesson_content_quizzes_questions } from "../../../../../../__generated__/gql-types/getLessonById";
import styles from "./orderFrase.module.scss";
interface PropsOrderFrase {
  index: number;
  question: getLessonById_getLessonById_lesson_content_quizzes_questions;
  subject_id?: number | null;
}

const OrderFrase: FC<PropsOrderFrase> = ({ question, index }) => {
  // Ordenar la copia
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{index + 1}. Ordenar la frase.</div>
        <div className={styles.containerBlock}>
          {question.answers
            ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 9))
            .map((answers) => (
              <div className={styles.containerAnswer}>{answers.answer}</div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default OrderFrase;
