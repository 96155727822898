import React, { FC, useEffect, useState } from "react";
import styles from "./containerTeacher.module.scss";
import sound from "../../../../../../../../assets/pages/quiz/soundConversation.png";
import chat2Image from "../../../../../../../../assets/images/chat2.png";
import { PropsMessage } from "../../../utils";
import { GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection_chat_log } from "../../../../../../../../__generated__/gql-types/GetChatLogForTeacherCorrection";
import { getLessonById_getLessonById } from "../../../../../../../../__generated__/gql-types/getLessonById";
import axios from "axios";
import { useAudio } from "../AudioContextProvider";

interface PropsContainerTeacher {
  message:
    | PropsMessage
    | GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection_chat_log;
  messages:
    | PropsMessage[]
    | GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection_chat_log[];
  individualLesson: getLessonById_getLessonById;
  autoPlay?: boolean;
  activeSound?: boolean;
}

const ContainerTeacher: FC<PropsContainerTeacher> = ({
  message,
  individualLesson,
  autoPlay,
  messages,
  activeSound,
}) => {
  const [textToAudio, setTextToAudio] = useState("");
  const { setCurrentAudio } = useAudio();
  const voiceGender =
    individualLesson?.lesson_content?.aiconversations?.voice ?? "male";
  const voiceSelector = (gender: string) => {
    return gender === "male" ? "en-US-Wavenet-D" : "en-US-Neural2-H";
  };

  const synthesizeText = async (text: string) => {
    if (!text) return "";
    try {
      const requestData = {
        input: {
          text: text,
        },
        voice: {
          languageCode: "en-US",
          name: voiceSelector(voiceGender),
        },
        audioConfig: { audioEncoding: "MP3", speakingRate: 0.8 },
      };

      const response = await axios.post(
        "https://texttospeech.googleapis.com/v1/text:synthesize",
        requestData,
        {
          params: {
            key: process.env.REACT_APP_API_KEY_GOOGLE,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const audioURL = `data:audio/mp3;base64,${response.data.audioContent}`;
      return audioURL;
    } catch (error) {
      console.error("Error al realizar la solicitud:", error);
      return "";
    }
  };

  const playAudio = (audioURL: string) => {
    const audio = new Audio(audioURL);
    setCurrentAudio(audio);
  };
  useEffect(() => {
    let typedMessage = message as PropsMessage;
    (async () => {
      const synthesizedText = await synthesizeText(message.message ?? "");
      setTextToAudio(synthesizedText ?? "");
      if (autoPlay && activeSound) {
        if (!message.notUnderstood && synthesizedText) {
          if (typedMessage.position === "second_to_last") {
          } else if (typedMessage.position === "last") {
            const secondToLastSyntheText = await synthesizeText(
              messages[messages.length - 2].message ?? ""
            );
            const secondToLastMessage =
              messages[messages.length - 2].message ?? "";
            const msgDuration = (secondToLastMessage.length / 12) * 1000;
            playAudio(secondToLastSyntheText);
            setTimeout(() => {
              playAudio(synthesizedText);
            }, msgDuration);
          } else {
            playAudio(synthesizedText);
          }
        }
      }
    })();
    return () => {
      setCurrentAudio(null);
    };
  }, [message.message]);

  return (
    <div className={styles.containerAI}>
      <img
        src={
          individualLesson.lesson_content.aiconversations?.avatar_image ?? ""
        }
        alt={chat2Image}
        className={styles.imgAi}
      />
      <div className={styles.containerTextAi}>
        <div className={styles.textAI}>{message.message}</div>
        {!message.notUnderstood && (
          <img
            src={sound}
            alt="sound"
            onClick={() => {
              if (message.message) {
                activeSound && playAudio(textToAudio);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ContainerTeacher;
