/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum LessonOrigin {
  extra_points = "extra_points",
  homework = "homework",
}

export enum Roles {
  admin = "admin",
  school = "school",
  student = "student",
  teacher = "teacher",
}

export enum scoreTypes {
  english_score = "english_score",
  geography_score = "geography_score",
  main_grade_leaderboard_score = "main_grade_leaderboard_score",
  main_score = "main_score",
  math_grade_leaderboard_score = "math_grade_leaderboard_score",
  math_score = "math_score",
  science_grade_leaderboard_score = "science_grade_leaderboard_score",
  science_score = "science_score",
  spanish_grade_leaderboard_score = "spanish_grade_leaderboard_score",
  spanish_score = "spanish_score",
}

export enum statusCodes {
  finished = "finished",
  inCourse = "inCourse",
  programed = "programed",
  unchecked = "unchecked",
}

export enum userActions {
  changed_avatar = "changed_avatar",
  completed_course = "completed_course",
  finished_lesson = "finished_lesson",
  finished_quiz = "finished_quiz",
}

export interface AIChatLogInput {
  user_id?: number | null;
  homework_id?: number | null;
  chat_log?: chat_log_input[] | null;
}

export interface AnswerInput {
  id?: string | null;
  answer?: string | null;
  image?: string | null;
  image_aspect?: string | null;
  correct?: number | null;
  order?: number | null;
  question_id?: number | null;
}

export interface AxesInput {
  id?: string | null;
  name?: string | null;
  videos?: VideoInput[] | null;
  courses?: CourseInput[] | null;
  lessons?: LessonInput[] | null;
  quizzes?: QuizInput[] | null;
}

export interface BlockContainerInput {
  image?: string | null;
  text?: string | null;
  coincidence?: number | null;
  totalItems?: number | null;
}

export interface BlockInput {
  id?: string | null;
  title?: string | null;
  publish?: number | null;
  description?: string | null;
  order_by?: number | null;
  grade_id?: number | null;
  subject_id?: number | null;
  program_id?: number | null;
}

export interface BlockItemsInput {
  image?: string | null;
  text?: string | null;
  coincidence?: number | null;
}

export interface ClassroomId {
  id?: number | null;
}

export interface ClassroomLessonInput {
  start_date?: string | null;
  classroom_id?: number | null;
  lesson?: number | null;
}

export interface CourseInput {
  id?: string | null;
  order_by?: number | null;
  block_id?: number | null;
  subject_id?: number | null;
  grade_id?: number | null;
  featured?: number | null;
  publish?: number | null;
  nem?: boolean | null;
  program_2017?: boolean | null;
  program_2425?: boolean | null;
  english?: boolean | null;
  reading?: boolean | null;
  type?: string | null;
  title?: string | null;
  normalizedTitle?: string | null;
  description?: string | null;
  initial_evaluation?: number | null;
  programs?: ProgramInput[] | null;
  lessons?: LessonInput[] | null;
  axes?: AxesInput[] | null;
  themes?: ThemeInput[] | null;
  tags?: TagInput[] | null;
  week?: number | null;
  week_2017?: number | null;
  week_2425?: number | null;
  week_english?: number | null;
  week_reading?: number | null;
  sep_book_pages?: string | null;
  sep_book_pages_2017?: string | null;
  sep_book_pages_2425?: string | null;
  sep_book_pages_english?: string | null;
  sep_book_pages_reading?: string | null;
  threemester?: number | null;
  threemester_2017?: number | null;
  threemester_2425?: number | null;
  threemester_english?: number | null;
  threemester_reading?: number | null;
  month?: number | null;
}

export interface DAndDQuestionInput {
  id?: string | null;
  blockItems?: BlockItemsInput[] | null;
  blockContainer?: BlockContainerInput[] | null;
  question_id?: number | null;
}

export interface EvaluateLessonInput {
  lesson_id?: number | null;
  userAnswers?: UserAnswer[] | null;
  origin?: LessonOrigin | null;
}

export interface Excluded_axes_id {
  id?: number | null;
}

export interface Excluded_themes_id {
  id?: number | null;
}

export interface GetClassroomsByStudentIdInput {
  searchArgs?: string | null;
}

export interface GetClassroomsByTeacherIdInput {
  searchArgs?: string | null;
}

export interface GetCoursesBySubjectIdInput {
  subject_id?: number | null;
  grade_id?: number | null;
  paginationArgs?: PaginationArgs | null;
  searchArgs?: string | null;
}

export interface GetCoursesBySubjectIdInputProgram2017 {
  subject_ids?: (number | null)[] | null;
  grade_id?: number | null;
  threemester_2017?: number | null;
  month?: number | null;
}

export interface GetCoursesBySubjectIdInputProgram2425 {
  subject_ids?: (number | null)[] | null;
  grade_id?: number | null;
  threemester_2425?: number | null;
  month?: number | null;
}

export interface GetCoursesBySubjectIdInputV2 {
  subject_id?: number | null;
  grade_id?: number | null;
  threemester?: number | null;
  month?: number | null;
}

export interface GetCoursesBySubjectIdInputV2NEM {
  subject_ids?: (number | null)[] | null;
  grade_id?: number | null;
  threemester?: number | null;
  month?: number | null;
}

export interface GetCoursesInputLiteracy {
  grade_id?: number | null;
  threemester?: number | null;
  month?: number | null;
}

export interface GetCoursesSearchArgs {
  search?: string | null;
  grade_ids?: (number | null)[] | null;
  subject_ids?: (number | null)[] | null;
  pageSize?: number | null;
  page?: number | null;
}

export interface GetLessonsByCourseInput {
  course_id?: number | null;
}

export interface GetLessonsBySubjectInput {
  subject_id: string;
  paginationArgs?: PaginationArgs | null;
  week?: number | null;
  sep_book_pages?: string | null;
  block_id?: number | null;
  grade_id?: number | null;
  searchArgs?: string | null;
}

export interface GetLessonsSearchArgs {
  search?: string | null;
  grade_ids?: (number | null)[] | null;
  subject_ids?: (number | null)[] | null;
  pageSize?: number | null;
  page?: number | null;
}

export interface GetPDACoursesSearchArgs {
  search?: string | null;
  grade_ids?: (number | null)[] | null;
  subjects?: (string | null)[] | null;
  pageSize?: number | null;
  page?: number | null;
}

export interface GetProjectsSearchArgs {
  search?: string | null;
  grade_ids?: number[] | null;
  pageSize?: number | null;
  page?: number | null;
}

export interface GroupInput {
  id?: string | null;
  name?: string | null;
  grade_id?: number | null;
  publish?: number | null;
  users?: UserId[] | null;
  school_id?: number | null;
}

export interface HomeworkInput {
  title?: string | null;
  description?: string | null;
  status?: statusCodes | null;
  start_date?: string | null;
  end_date?: string | null;
  send_email?: boolean | null;
  date_to_email?: string | null;
  classroom_id?: number[] | null;
  tries?: number | null;
  group_id?: number | null;
  users?: (number | null)[] | null;
  lessons?: (number | null)[] | null;
  needs_file_evidence?: boolean | null;
  teacher_file_url?: string | null;
  students_ids?: (number | null)[] | null;
}

export interface LessonContentInput {
  id?: string | null;
  lesson_id?: number | null;
  quizzes?: QuizInput[] | null;
  videos?: VideoInput[] | null;
  redaction_id?: number | null;
  literacy_id?: number | null;
  pdf?: string | null;
  slide?: string | null;
  responses_pdf?: string | null;
  aiconversations_id?: number | null;
}

export interface LessonInput {
  id?: string | null;
  title?: string | null;
  description?: string | null;
  cover?: string | null;
  week?: number | null;
  sep_book_pages?: string | null;
  order_by?: number | null;
  order_reading?: number | null;
  sep_name?: string | null;
  nem?: boolean | null;
  drive_link?: string | null;
  publish?: number | null;
  grade_id?: number | null;
  subject_id?: number | null;
  featured?: number | null;
  axes?: AxesInput[] | null;
  themes?: ThemeInput[] | null;
  tags?: TagInput[] | null;
  lesson_content?: LessonContentInput | null;
  status?: string | null;
  lesson_type?: string | null;
  learnings?: string | null;
}

export interface MatchQuestionInput {
  id?: string | null;
}

export interface PaginationArgs {
  resultsPerPage?: number | null;
  currentPageNumber?: number | null;
}

export interface ProgramInput {
  id?: string | null;
  title?: string | null;
  description?: string | null;
  publish?: number | null;
  order_by?: number | null;
  subject_id?: number | null;
  grade_id?: number | null;
  courses?: CourseInput[] | null;
  blocks?: BlockInput[] | null;
}

export interface QuestionBlockInput {
  image?: string | null;
  text?: string | null;
  order?: number | null;
  coincidence?: number | null;
  totalItems?: number | null;
  url?: string | null;
}

export interface QuestionInput {
  id?: string | null;
  question?: string | null;
  image?: string | null;
  image_aspect?: string | null;
  paragraph?: string | null;
  order?: number | null;
  type_id?: number | null;
  quizzes?: QuizInput[] | null;
  answers?: AnswerInput[] | null;
  relationQuestion?: RelationQuestionInput[] | null;
  matchQuestion?: MatchQuestionInput[] | null;
  dAndDQuestion?: DAndDQuestionInput[] | null;
}

export interface QuestionRelationInput {
  image?: string | null;
  text?: string | null;
  coincidence?: number | null;
  url?: string | null;
}

export interface QuizAnswers {
  question_id?: number | null;
  answer_id: number[];
}

export interface QuizInput {
  id?: string | null;
  subject_id?: number | null;
  title?: string | null;
  filename?: string | null;
  publish?: number | null;
  order?: number | null;
  themes?: ThemeInput[] | null;
  axes?: AxesInput[] | null;
  questions?: QuestionInput[] | null;
  videos?: VideoInput[] | null;
  lesson_content?: LessonContentInput[] | null;
  quiz_type?: string | null;
}

export interface RelationQuestionBlockInput {
  image?: string | null;
  text?: string | null;
  coincidence?: number | null;
}

export interface RelationQuestionInput {
  id?: string | null;
  block1?: (RelationQuestionBlockInput | null)[] | null;
  block2?: (RelationQuestionBlockInput | null)[] | null;
  question_id?: number | null;
}

export interface ResponsesInput {
  question_type?: number | null;
  homework_id?: number | null;
  partial_redaction_save?: boolean | null;
  post_homework_response?: boolean | null;
  redaction_id?: number | null;
  user_id?: number | null;
  lesson_id?: number | null;
  quiz_id?: number | null;
  question_id?: number | null;
  note?: number | null;
  AI_evaluation?: number | null;
  tries?: number | null;
  redaction_response?: string | null;
  input_response?: string | null;
  answers_responses?: (number | null)[] | null;
  block_responses?: ((QuestionBlockInput | null)[] | null)[] | null;
  relation_responses?: ((QuestionRelationInput | null)[] | null)[] | null;
}

export interface RubricObjCalficationInput {
  order?: number | null;
  calification?: number | null;
}

export interface SchoolInput {
  id?: string | null;
  cct?: string | null;
  name?: string | null;
  city?: string | null;
  classroomId?: (ClassroomId | null)[] | null;
  demo?: boolean | null;
  show_sep_pages?: boolean | null;
  show_grades_on_base_100?: boolean | null;
  excluded_themes?: (Excluded_themes_id | null)[] | null;
  excluded_axes?: (Excluded_axes_id | null)[] | null;
  view_literacy?: boolean | null;
  view_english?: boolean | null;
  view_project_generator?: boolean | null;
  view_2425?: boolean | null;
  view_NEM_student?: boolean | null;
  view_2017_student?: boolean | null;
  view_2425_student?: boolean | null;
  view_dynamics?: boolean | null;
  view_shop?: boolean | null;
  view_reading_spanish?: boolean | null;
  view_reading_english?: boolean | null;
}

export interface TagInput {
  id?: string | null;
  name?: string | null;
  lessons?: LessonInput[] | null;
  courses?: CourseInput[] | null;
}

export interface ThemeInput {
  id?: string | null;
  name?: string | null;
  videos?: VideoInput[] | null;
  courses?: CourseInput[] | null;
  lessons?: LessonInput[] | null;
  quizzes?: QuizInput[] | null;
}

export interface UserAnswer {
  quiz_id?: number | null;
  answers?: QuizAnswers[] | null;
}

export interface UserId {
  id?: number | null;
}

export interface UserInput {
  id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  username?: string | null;
  email?: string | null;
  password?: string | null;
  email_parent?: string | null;
  groups?: GroupInput[] | null;
  schools?: SchoolInput[] | null;
  role?: Roles | null;
  lessons?: (LessonInput | null)[] | null;
  avatar?: string | null;
  teacher_password?: string | null;
  student_id?: number | null;
}

export interface UserScoreInput {
  score?: number | null;
  action?: userActions | null;
  item_id?: number | null;
  scoreType?: scoreTypes | null;
  user_id?: number | null;
}

export interface VideoInput {
  id?: string | null;
  title?: string | null;
  file_id?: string | null;
  description?: string | null;
  image_cover?: string | null;
  order?: number | null;
  publish?: number | null;
  themes?: ThemeInput[] | null;
  axes?: AxesInput[] | null;
  metadata?: any | null;
  quizzes?: (VideoQuizQuizzesInput | null)[] | null;
  microcontent?: string | null;
  key_learnings?: string | null;
}

export interface VideoQuizQuizzesInput {
  quiz_id?: number | null;
  time?: number | null;
  time_out?: number | null;
}

export interface chat_log_input {
  message?: string | null;
  sender?: string | null;
  messageUser?: string | null;
  notUnderstood?: boolean | null;
  errorUser?: string | null;
}

export interface getCoursesForNewContentInput {
  grade_id?: number | null;
  threemester_english?: number | null;
  threemester_reading?: number | null;
  month?: number | null;
}

export interface redactionResponseCorrectionInput {
  rubrics_calification?: RubricObjCalficationInput[] | null;
  redaction_comments?: string | null;
  redaction_response?: string | null;
  subject_code?: string | null;
  note?: number | null;
  homework_id?: number | null;
  student_id?: number | null;
  redaction_teacher_response_correction?: string | null;
  redaction_gpt_response_correction?: string | null;
}

export interface teacherModalInfoInput {
  redaction_id: number;
  student_id: number;
  homework_id: number;
}

export interface teacherProjectInput {
  grade?: number | null;
  activity_type?: string | null;
  duration?: number | null;
  students?: number | null;
  week_hours?: number | null;
  url?: string | null;
  themes_of_interest?: string | null;
  selected_themes_ids?: number[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
