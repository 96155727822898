import { FC, useState } from "react";
import { Modal } from "antd";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import styles from "./selectAvatar.module.scss";
import useAuth from "../../../context/useAuth";
import useUpdateAvatar from "../../../api/useUpdateUserAvatar";
import UseGetUserByEmail from "../../../api/useGetUserByEmail";

// SVGs
import avatarElipse from "../../../assets/images/avatarElipse.svg";
import blueDiamond from "../../../assets/icons/students/blueDiamond.svg";
import { getUserByEmail_getUserByEmail } from "../../../__generated__/gql-types/getUserByEmail";
import { avatars } from "../../../utils/functions";
import { Loader } from "../../Commons";

interface SelectAvatarProps {
  userDb?: getUserByEmail_getUserByEmail | null;
  setUserDb?: (e: getUserByEmail_getUserByEmail | null) => void;
}

const SelectAvatar: FC<SelectAvatarProps> = ({ userDb, setUserDb }) => {
  const { userToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const updateUser = useUpdateAvatar();
  const navigate = useNavigate();
  const getUserByEmail = UseGetUserByEmail();
  const [visible, setVisible] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(null);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      ></div>
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      ></div>
    );
  };

  const handleSubmit = async (_event: any) => {
    setLoading(true);
    try {
      const updateResponse = await updateUser({
        userInput: {
          avatar: selectedAvatar,
        },
      });

      if (updateResponse.data.userSelectedAvatar.succes) {
        setVisible(false);
        const userRes = await getUserByEmail({
          userEmail: userDb?.email,
        });
        setUserDb && setUserDb(userRes);
        setLoading(false);
        navigate("/mymissions");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "40px",
          nextArrow: <></>,
          prevArrow: <></>,
          dots: true,
        },
      },
    ],
  };

  if (loading) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.selectAvatarText}>
        ¡Hola {userToken?.first_name}, es hora de seleccionar tu avatar!{" "}
      </h1>
      <h3>
        ¡Hola {userToken?.first_name}, <br /> es hora de seleccionar tu avatar!{" "}
      </h3>
      <h2>
        Tu avatar te acompañará durante este ciclo escolar y te ayudará a
        completar tus misiones.
      </h2>
      <div className={styles.sliderContainer}>
        <Slider {...settings}>
          {avatars.map((element) => {
            return (
              <div
                key={element.name}
                onClick={() => setSelectedAvatar(element?.name)}
              >
                <div
                  className={
                    element?.name === selectedAvatar
                      ? styles.sliderContentSelected
                      : styles.sliderContent
                  }
                >
                  <h1 className={styles.avatarName}> {element.name}</h1>
                  <div className={styles.imageContainer}>
                    <img src={element.image1} alt="" />
                    <img src={avatarElipse} alt="" className={styles.elipse} />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      <div className={styles.lastButton}>
        <span className={styles.text}>Selecciona tu avatar y consigue </span>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={blueDiamond} alt="blue_diamond" />
          <span className={styles.textPoints}> +100 </span>
        </span>
      </div>

      {selectedAvatar ? (
        <button className={styles.buttonSelected} onClick={showModal}>
          SELECCIONAR
        </button>
      ) : (
        <button className={styles.buttonNoSelected}>SELECCIONAR</button>
      )}
      <Modal
        style={{ marginTop: "3%", width: "90%" }}
        title=""
        visible={visible}
        // onOk={hideModal}
        onCancel={hideModal}
        footer={null}
      >
        <div className={styles.confirmAvatarModal}>
          <div className={styles.avatarBackground}>
            <img
              src={
                avatars.find((avatar) => avatar.name === selectedAvatar)?.image1
              }
              alt=""
            />
          </div>
          <h1>
            ¡Has seleccionado a <span>{selectedAvatar}</span>!
          </h1>
          <h2>
            {" "}
            Será quien te acompañe durante este curso. <br /> Completa misiones
            para que tu avatar evolucione.
          </h2>
          <button className={styles.modalPoints}>
            Puntos{" "}
            <span>
              {" "}
              <img src={blueDiamond} alt="blue_diamond" />
              <span className={styles.textPoints}> +100 </span>{" "}
            </span>
          </button>
          <div className={styles.modalBtnContainer}>
            <button
              className={styles.buttonNoSelected}
              onClick={() => {
                setVisible(false);
                setSelectedAvatar(null);
              }}
            >
              CANCELAR
            </button>
            <button className={styles.buttonSelected} onClick={handleSubmit}>
              CONFIRMAR
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SelectAvatar;
