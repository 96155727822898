import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getResponsesByHomework,
  getResponsesByHomeworkVariables,
} from "../__generated__/gql-types/getResponsesByHomework";

const useGetResponsesByHomework = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getResponsesByHomeworkVariables) => {
      const result: ApolloQueryResult<getResponsesByHomework> =
        await client.query({
          query: GET_RESPONSES_BY_HOMEWORK,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getResponsesByHomework;
    },
    [client]
  );
};

export default useGetResponsesByHomework;

const GET_RESPONSES_BY_HOMEWORK = gql`
  query getResponsesByHomework($homeworkId: Int) {
    getResponsesByHomework(homework_id: $homeworkId) {
      student_order_phrase {
        id
        correct
        answer
      }
      question_id
      question_type
      question
      paragraph
      student_input_responses {
        response
        correct
      }
      student_ftbdad_responses {
        answer_id
        correct
        answer
        percent
      }
      student_relation_DaD_responses {
        first
        second
        percent
      }
      image
      answers {
        answer_id
        answer
        correct
        order
        percent
        image
      }
      relationQuestion {
        id
        block1 {
          image
          text
          coincidence
          url
        }
        block2 {
          image
          text
          coincidence
          url
        }
        question_id
        created_at
        updated_at
        deleted_at
      }
      matchQuestion {
        id
      }
      dAndDQuestion {
        id
        blockItems {
          image
          text
          coincidence
          url
        }
        blockContainer {
          image
          text
          coincidence
          totalItems
          url
        }
        question_id
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;
