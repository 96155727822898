import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import styles from "./layout.module.scss";
import { useLocation } from "react-router-dom";
import SiderLuca from "./Sider/SiderLuca";
import { SelectAvatar } from "../Students/SelectAvatar";
import useIsMobile from "../../hooks/useIsMobile";
import { ModalReward } from "./ModalReward";
import { getUserByEmail_getUserByEmail } from "../../__generated__/gql-types/getUserByEmail";
import useIsTablet from "../../hooks/useIsTablet";
import { ViewTablet } from "./ViewTablet";

const { Content } = Layout;

interface LayoutProps {
  children: React.ReactNode;
  userDb?: getUserByEmail_getUserByEmail | null;
  setUserDb?: (e: getUserByEmail_getUserByEmail | null) => void;
}

const LayoutLuca: React.FC<LayoutProps> = ({ children, userDb, setUserDb }) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const lesson = location.pathname.slice(1, 5);
  const [openReward, setReward] = useState(false);

  const onCloseModalReward = () => {
    setReward(false);
  };

  useEffect(() => {
    userDb &&
      userDb.avatar_state &&
      typeof userDb.avatar_state.show_finish_tournament === "boolean" &&
      setReward(true);
  }, [userDb]);

  const returnMobile = () => {
    return isMobile ? (
      <div className={styles.container}>
        <div className={styles.contentContainer}>{children}</div>
        <div className={styles.footerMobile}>
          <SiderLuca userDb={userDb} />
          {userDb?.avatar_state?.show_finish_tournament && (
            <ModalReward
              onCloseModalPosition={onCloseModalReward}
              openModal={openReward}
              userScore={userDb}
            ></ModalReward>
          )}
        </div>
      </div>
    ) : isTablet ? (
      <ViewTablet />
    ) : (
      <Layout style={{ minHeight: "100vh" }}>
        <SiderLuca userDb={userDb} />
        {/* <LucaChat /> */}
        <Layout style={{ background: "white" }}>
          <Content style={{ background: "none !important" }}>
            <div className={styles.siteLayout}>{children}</div>
            {userDb?.avatar_state?.show_finish_tournament && (
              <ModalReward
                onCloseModalPosition={onCloseModalReward}
                openModal={openReward}
                userScore={userDb}
              ></ModalReward>
            )}
          </Content>
        </Layout>
      </Layout>
    );
  };

  return (
    <>
      {((userDb?.avatar && userDb?.role === "student") ||
        userDb?.role === "teacher" ||
        userDb?.role === "admin") &&
        (lesson !== "task" && lesson !== "noSc" ? (
          returnMobile()
        ) : (
          <div> {children}</div>
        ))}
      {!userDb?.avatar && userDb?.role === "student" && (
        <SelectAvatar userDb={userDb} setUserDb={setUserDb} />
      )}
      {userDb?.avatar_state?.show_finish_tournament && (
        <ModalReward
          onCloseModalPosition={onCloseModalReward}
          openModal={openReward}
          userScore={userDb}
        ></ModalReward>
      )}
    </>
  );
};

export default LayoutLuca;
