import { Modal } from "antd";
import React, { FC } from "react";
import styles from "./modalError.module.scss";
import { useNavigate } from "react-router-dom";
import lucaBot from "./../../../../../assets/images/lucaBotError.svg";
import useAuth from "../../../../../context/useAuth";

interface PropsModalDelete {
  open: boolean;
  onCloseModalDelete: () => void;
  message?: string;
}

const ModalDelete: FC<PropsModalDelete> = ({
  open,
  onCloseModalDelete,
  message,
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  return (
    <Modal
      open={open}
      onCancel={onCloseModalDelete}
      closable={true}
      width={"500px"}
      footer={""}
      centered
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.containerText}>
            <div className={styles.containerTitle}>
              <div className={styles.title}>¡Ooops!</div>
              <div className={styles.subtitle}>hemos tenido un error.</div>
            </div>
            <div className={styles.textGrey}>
              {message ? message : "Sentimos mucho cualquier molestia causada."}
            </div>
          </div>
          <img src={lucaBot} alt="default" />
        </div>

        <div className={styles.footerDetail}>
          <button
            className={styles.taskButtonMision}
            onClick={async () => {
              return navigate("/");
            }}
          >
            Reintentar
          </button>
          <a className={styles.hiperlinkSessionClose} onClick={logout}>
            Cerrar sesión
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDelete;
