import { FC, useState, useEffect } from "react";
import moment from "moment";
import styles from "./table.module.scss";
import { toLocalDate } from "../../../utils/functions";

// Components
import { Table } from "antd";
import { PaginationProgressBar } from "../../Shared/PaginationProgressBar";
import { DetailsHomeworkDrawer } from "../DetailsHomeworkDrawer";

// Icons
import check from "../../../assets/icons/checked_green.svg";
import calendary from "../../../assets/icons/calendar-icon.svg";
import circleInProgress from "../../../assets/icons/inCourse-icon.svg";

import type { ColumnsType } from "antd/es/table";
import {
  getClassroomWithHomeworks_getClassroomWithHomeworks,
  getClassroomWithHomeworks_getClassroomWithHomeworks_homeworks,
} from "../../../__generated__/gql-types/getClassroomWithHomeworks";
import UseClassroomWithHomeworks from "../../../api/useGetClassroomWithHomeworks";
import { Loader } from "../../Commons";
import { lessonTypeIconResolve } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";

interface TableProps {
  selectedStatusTask: string;
  dataSource: getClassroomWithHomeworks_getClassroomWithHomeworks;
  setIndividualClassroom: (
    e: getClassroomWithHomeworks_getClassroomWithHomeworks | null
  ) => void;
  setIsLoading?: (e: boolean) => void;
  isLoading?: boolean;
  classroomId: string;
}
export const isInt = (num: any) => {
  return parseInt(num) === num;
};

const TableComponent: FC<TableProps> = ({
  dataSource,
  setIndividualClassroom,
  selectedStatusTask,
  classroomId,
}) => {
  const getClassroomWithHomeworks = UseClassroomWithHomeworks();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const homeworkIdParams = queryParams.get("homeworkId");
  const [studentsHomeworks, setStudentsHomeworks] =
    useState<getClassroomWithHomeworks_getClassroomWithHomeworks_homeworks[]>();
  const [visibleDrawerDetail, setVisibleDrawerDetail] = useState(
    homeworkIdParams ? true : false
  );
  const [singleTask, setSingleTask] = useState({});
  const [taskId, setTaskId] = useState<string | null>(
    homeworkIdParams ? homeworkIdParams : null
  );
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] =
    useState<getClassroomWithHomeworks_getClassroomWithHomeworks_homeworks[]>();
  const [tasksPagination, setTasksPagination] =
    useState<getClassroomWithHomeworks_getClassroomWithHomeworks_homeworks[]>();

  const showDrawer = () => {
    setVisibleDrawerDetail(true);
  };

  const handleSearchClick = (id: string) => {
    const params = new URLSearchParams(location.search);
    params.set("homeworkId", id);
    navigate(`?${params.toString()}`);
  };

  const functionRefreshTable = async () => {
    try {
      const classroomsRes = await getClassroomWithHomeworks({
        classroomId: dataSource?.classroom?.id!,
      });
      classroomsRes?.homeworks &&
        setStudentsHomeworks(classroomsRes?.homeworks);
      setIndividualClassroom(classroomsRes);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  const onClose = () => {
    functionRefreshTable();
    setVisibleDrawerDetail(false);
  };

  useEffect(() => {
    setTasksPagination(
      tasks
        ?.sort((a, b) => {
          if (a.created_at < b.created_at) {
            return 1;
          }
          if (a?.created_at > b?.created_at) {
            return -1;
          }
          return 0;
        })
        .slice(0, limit)
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [tasks, limit, dataSource, studentsHomeworks]);

  useEffect(() => {
    const fetchHomework = async () => {
      const studentsClassroomHomeworks = await getClassroomWithHomeworks({
        classroomId: classroomId,
      });

      studentsClassroomHomeworks?.homeworks &&
        setStudentsHomeworks(studentsClassroomHomeworks?.homeworks);
    };

    fetchHomework();
  }, [classroomId, getClassroomWithHomeworks]);

  useEffect(() => {
    if (selectedStatusTask === "all") {
      const tasksFilter = studentsHomeworks?.filter(
        (a) => a && a.deleted_at === null
      );

      return setTasks(tasksFilter ?? []);
    }

    const tasksFilter = studentsHomeworks?.filter(
      (a) => a && a.status === selectedStatusTask && a.deleted_at === null
    );

    setTasks(tasksFilter);
  }, [selectedStatusTask, dataSource, studentsHomeworks]);

  const columns: ColumnsType<getClassroomWithHomeworks_getClassroomWithHomeworks_homeworks> =
    [
      {
        title: "ESTADO",
        dataIndex: "status",
        key: "status",
        width: 150,
        render: (status) => {
          return (
            <>
              {status === "programed" && (
                <div className={styles.statusProgrammed}>
                  <img src={calendary} alt="" />
                  <h3>Programada</h3>
                </div>
              )}

              {status === "inCourse" && (
                <div className={styles.statusInCourse}>
                  <img src={circleInProgress} alt="" />
                  <h3> En curso </h3>
                </div>
              )}

              {status === "finished" && (
                <div className={styles.statusContainer}>
                  <img src={check} alt="" />
                  <h3>Finalizada</h3>
                </div>
              )}
            </>
          );
        },
      },
      {
        title: "TIPO",
        dataIndex: "type",
        key: "type",
        width: 100,
        align: "center",
        render: (_, homework) => {
          const lessonType =
            homework?.lessons && homework?.lessons[0]?.lesson_type;
          return (
            <>
              <img src={lessonTypeIconResolve(lessonType)} alt="" />
            </>
          );
        },
      },
      {
        title: "TAREA",
        dataIndex: "title",
        key: "title",
        width: 350,
        render: (_, title) => {
          return (
            <div className={styles.descriptionContainer}>
              {title.status === "unchecked" ? (
                <h3 className={styles.descriptionFontWithoutRevision}>
                  {title.title}
                </h3>
              ) : (
                <p className={styles.descriptionFont}>{title.title}</p>
              )}
            </div>
          );
        },
      },

      {
        title: "COMPLETADA",
        dataIndex: "participation",
        key: "participation",
        width: 100,
        align: "center",
        render: (_: any, record) => {
          return (
            <div className={styles.descriptionContainerCenter}>
              <p className={styles.descriptionFontWithoutRevision}>
                {record.participation}%
              </p>
            </div>
          );
        },
      },
      {
        title: "PROMEDIO",
        dataIndex: "average",
        key: "average",
        align: "center",
        sorter: (a: any, b: any) => b.average - a.average,
        width: 100,
        render: (_: any, record) => {
          return (
            <div className={styles.descriptionContainerCenter}>
              <p className={styles.descriptionFontWithoutRevision}>
                {Number.isInteger(record?.average)
                  ? record?.average
                  : record?.average?.toFixed(1)}
              </p>
            </div>
          );
        },
      },
      {
        title: "INICIO",
        dataIndex: "start_date",
        key: "start_date",
        width: 100,
        align: "center",
        sorter: (a: any, b: any) =>
          moment(a.start_date?.slice(0, 10), "DD-MM-YYYY").unix() -
          moment(b.start_date?.slice(0, 10), "DD-MM-YYYY").unix(),
        // sortOrder: sortedInfo.columnKey === "start_date" && sortedInfo.order,
        render: (start_date, { status }) => {
          const finalDate = toLocalDate(start_date)
            ?.replace(/-\d{4}/, "")
            .replace(/(\d{2}:\d{2}).*/, "$1");
          return (
            <div className={styles.descriptionContainer}>
              {status === "unchecked" ? (
                <h3 className={styles.descriptionFontWithoutRevision}>
                  {finalDate}
                </h3>
              ) : (
                <h3 className={styles.descriptionFont}>{finalDate}</h3>
              )}
            </div>
          );
        },
      },
      {
        title: "FIN",
        dataIndex: "end_date",
        key: "end_date",
        width: 100,
        align: "center",
        render: (end_date, { status }) => {
          const finalDate = toLocalDate(end_date)
            ?.replace(/-\d{4}/, "")
            .replace(/(\d{2}:\d{2}).*/, "$1");
          return (
            <div className={styles.descriptionContainer}>
              {status === "unchecked" ? (
                <h3 className={styles.descriptionFontWithoutRevision}>
                  {finalDate}
                </h3>
              ) : (
                <h3 className={styles.descriptionFont}>{finalDate}</h3>
              )}
            </div>
          );
        },
      },
    ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Table
            dataSource={tasksPagination}
            pagination={false}
            columns={columns}
            loading={loading}
            rowClassName={styles.cursorPoint}
            onRow={(record) => {
              return {
                onClick: () => {
                  showDrawer();
                  setTaskId(record.id);
                  setSingleTask(record);

                  handleSearchClick(record.id);
                },
              };
            }}
          />
        </div>
      )}
      <PaginationProgressBar
        limit={limit}
        setLimit={setLimit}
        itemsLength={tasksPagination?.length ?? 0}
        totalLength={tasks?.length ?? 0}
      />
      {visibleDrawerDetail && (
        <DetailsHomeworkDrawer
          back="myclasses"
          taskId={taskId}
          singleTask={singleTask}
          onCloseTask={onClose}
          visibleTask={visibleDrawerDetail}
        />
      )}
    </>
  );
};

export default TableComponent;
