import { FC, useState, useEffect } from "react";
import { Drawer, Space, Button, Checkbox, List, Collapse } from "antd";
import styles from "./assingHomeworkDrawer.module.scss";
import cross from "../../assets/images/cross.svg";
import moment from "moment";
// Api && Types
import TeacherClassroooms from "../../utils/UseTeacherClassRooms";
import { getLessonById_getLessonById } from "../../__generated__/gql-types/getLessonById";

import {
  notificationNegative,
  NotificationPositive,
} from "../Shared/Notifacation/NotificationLuca";
import { HeaderDrawerMobile } from "../Mobile/Shared/HeaderDrawerMobile";
import {
  getClassroomsByTeacher,
  getClassroomsByTeacher_getClassroomsByTeacher,
} from "../../__generated__/gql-types/getClassroomsByTeacher";
import UseCreateClassroomLesson from "../../api/useCreateClassroomLesson";
import { toLocalDate, toUtcDate } from "../../utils/functions";
import UseClassroomLessonById from "../../api/useGetClassroomLessonById";
import useUpdateClassroomLesson from "../../api/useUpdateClassroomLessonById";
import useTeacherAssignClassroomLessonActionEvent from "../../api/useTeacherAssignClassroomLessonActionEvent";
import useTeacherAssignClassroomLessonNEMActionEvent from "../../api/useTeacherAssignClassroomLessonNEMActionEvent";
import useIsMobile from "../../hooks/useIsMobile";
import dayjs from "dayjs";
import { AssingDataClassroom } from "./AssingDataClassroom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { DependencyRefresh } from "../../redux/store/store";
import { setDependencyRefresh } from "../../redux/slices/dependencyRefresh";

interface AssingHomeworkDrawerProps {
  incomingSelectedLesson?: getLessonById_getLessonById | null;
  incomingSelectedClassroom?: any;
  onClose: () => void;
  visible: boolean;
  setIsLoading?: (e: boolean) => void;
  setIndividualClassroom?: (e: getClassroomsByTeacher) => void;
  onCloseDrawerLesson?: () => void;
  taskId?: string;

  setIsClassroomLessonsModalVisible?: (e: boolean) => void;
}

export const AssingClassroomLesson: FC<AssingHomeworkDrawerProps> = ({
  onClose,
  setIsLoading,
  visible,
  onCloseDrawerLesson,

  taskId,
  incomingSelectedLesson,
  incomingSelectedClassroom,
  setIsClassroomLessonsModalVisible,
}) => {
  const isMobile = useIsMobile();

  const createClassroomLesson = UseCreateClassroomLesson();
  const getClassroomLessonById = UseClassroomLessonById();
  const updateClassroomLesson = useUpdateClassroomLesson();
  const teacherAssignClassroomLessonActionEvent =
    useTeacherAssignClassroomLessonActionEvent();
  const teacherAssignClassroomNEMLessonActionEvent =
    useTeacherAssignClassroomLessonNEMActionEvent();
  const { Panel } = Collapse;
  const timeFormat = "HH:mm";
  const dateFormat = "DD-MM-YYYY";
  const hour = moment().format(timeFormat).toString();
  const date = moment().format(dateFormat).toString();
  const currentURL = window.location.href;
  const [classroomLesson, setClassroomLesson] = useState<any>({
    start_date: null,
    classroom_id: null,
    lesson: null,
  });
  const dispatch = useAppDispatch();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const [startDate, setStartDate] = useState<string | dayjs.Dayjs>(date);
  const [startTime, setStartTime] = useState<string | dayjs.Dayjs>(hour);
  const [selectedLesson, setSelectedLesson] = useState<any>();
  const [activeKey, setActiveKey] = useState<number>(taskId ? 3 : 0);

  let finalTeacherClassrooms:
    | getClassroomsByTeacher_getClassroomsByTeacher[]
    | undefined = [];

  finalTeacherClassrooms = TeacherClassroooms.getTeacherClassrooms()?.filter(
    (classroom: getClassroomsByTeacher_getClassroomsByTeacher) => {
      const lessonSubjectId =
        incomingSelectedLesson?.subject?.id === "17"
          ? "2"
          : incomingSelectedLesson?.subject?.id;
      return (
        Number(classroom?.program?.subject?.id) === Number(lessonSubjectId)
      );
    }
  );

  const [classRoom, setClassRoom] =
    useState<getClassroomsByTeacher_getClassroomsByTeacher | null>(
      finalTeacherClassrooms?.length && finalTeacherClassrooms.length === 1
        ? finalTeacherClassrooms[0]
        : null
    );
  const onCloseDrawer = () => {
    setSelectedLesson(null);
    setStartDate("");
    setStartTime("");
    onClose();
  };

  const isFormComplete = (homework: any): boolean => {
    return homework?.classroom_id &&
      homework?.start_date?.length === 16 &&
      homework.lesson !== null &&
      !isNaN(homework.lesson)
      ? true
      : false;
  };

  const programPanelTitle = (
    startDate: string | dayjs.Dayjs,
    date: string
  ): string => {
    return startDate === date
      ? `Hoy ${startTime} `
      : startDate !== date
      ? `${startDate} ${startTime}`
      : startDate.length
      ? `${startDate} ${startTime}`
      : "--";
  };
  const classRoomPanelTitle = () => {
    return classRoom?.name?.length
      ? classRoom?.name
      : incomingSelectedClassroom
      ? incomingSelectedClassroom.name
      : "--";
  };

  const classRoomContent = () => {
    let finalTeacherClassrooms:
      | getClassroomsByTeacher_getClassroomsByTeacher[]
      | undefined = [];
    finalTeacherClassrooms = TeacherClassroooms.getTeacherClassrooms()?.filter(
      (classroom) => {
        return (
          Number(classroom?.program?.subject?.id) ===
          Number(incomingSelectedLesson?.subject?.id)
        );
      }
    );

    return finalTeacherClassrooms?.length &&
      finalTeacherClassrooms.length === 1 ? (
      <List
        dataSource={finalTeacherClassrooms}
        renderItem={(item, i) => (
          <List.Item className={styles.listItem}>
            <div className={styles.listItemContainer}>
              {item.name}{" "}
              <Checkbox
                checked={classRoom?.id === item.id ? true : false}
                name={item.name ? item.name : undefined}
              ></Checkbox>
            </div>
          </List.Item>
        )}
      />
    ) : finalTeacherClassrooms?.length && finalTeacherClassrooms.length > 1 ? (
      <List
        dataSource={finalTeacherClassrooms}
        renderItem={(item, i) => (
          <List.Item className={styles.listItem}>
            <div className={styles.listItemContainer}>
              {item.name}{" "}
              <Checkbox
                onChange={() => {
                  setClassRoom(item);
                }}
                checked={classRoom?.id === item.id ? true : false}
                name={item.name ? item.name : undefined}
              ></Checkbox>
            </div>
          </List.Item>
        )}
      />
    ) : (
      <p style={{ fontFamily: "Poppins-Regular" }}>
        No posees clases asignadas a tu nombre o no existen clases
        correspondientes a la asignatura elegida.{" "}
      </p>
    );
  };
  // functions for disabling periods of time on date & time pickers

  // a custom footer for the collapse panels
  const CustomPanelFooter = (
    propertyName: string | number,
    value: string | number | (string | number)[]
  ) => {
    const clasroomLessonState = { ...classroomLesson };
    const finalClassroomLessonState = Object.defineProperty(
      clasroomLessonState,
      propertyName,
      {
        value: value,
      }
    );

    return (
      <div className={styles.classroomListSelectButtonContainer}>
        <div
          className={styles.taskButton}
          onClick={() => {
            setClassroomLesson(finalClassroomLessonState);
            setActiveKey(0);
          }}
        >
          Confirmar
        </div>
      </div>
    );
  };
  const handleSubmit = async () => {
    setSelectedLesson(null);
    setClassRoom(null);
    setStartDate("");
    setStartTime("");
    setIsLoading && setIsLoading(true);
    onCloseDrawerLesson && onCloseDrawerLesson();
    setIsClassroomLessonsModalVisible &&
      setIsClassroomLessonsModalVisible(true);
    try {
      if (taskId) {
        await updateClassroomLesson({
          classroomLessonId: taskId,
          classroomLessonInput: {
            ...classroomLesson,
            lesson: Number(classroomLesson.lesson),
            start_date: toUtcDate(`${startDate} ${startTime}`).replaceAll(
              "-",
              "/"
            ),
          },
        });
        NotificationPositive(
          "¡Estupendo! La tarea ha sido modificada con éxito."
        );
        setIsClassroomLessonsModalVisible !== undefined &&
          setIsClassroomLessonsModalVisible(false);
      } else {
        await createClassroomLesson({
          ...classroomLesson,
          start_date: toUtcDate(`${startDate} ${startTime}`).replaceAll(
            "-",
            "/"
          ),
        });
        NotificationPositive(
          "¡Estupendo! La lección ha sido planeada con éxito."
        );
        setIsClassroomLessonsModalVisible !== undefined &&
          setIsClassroomLessonsModalVisible(false);
        if (currentURL.includes("nem")) {
          teacherAssignClassroomNEMLessonActionEvent({
            contentId: Number(incomingSelectedLesson?.id),
          });
        } else {
          teacherAssignClassroomLessonActionEvent({
            contentId: Number(incomingSelectedLesson?.id),
          });
        }
      }
    } catch (error) {
      String(error).includes("lección asignada")
        ? notificationNegative(
            "¡Ups! Ya tienes una tarea en curso con esa lección asignada. Por favor, elimínala o espera a que finalice."
          )
        : notificationNegative();
    }
    setIsClassroomLessonsModalVisible !== undefined &&
      setIsClassroomLessonsModalVisible(false);
    dispatch(setDependencyRefresh(!dependencyRefresh)); // Hacer Refresh
    onClose();
  };

  useEffect(() => {
    (async () => {
      if (taskId) {
        const responseClassroomLesson = await getClassroomLessonById({
          classroomLessonId: taskId,
        });
        setClassroomLesson({
          classroom_id: responseClassroomLesson?.classroom_id,
          lesson: responseClassroomLesson?.lesson?.id,
          start_date: toLocalDate(responseClassroomLesson?.start_date),
        });

        let oldHomeworkStartDate =
          responseClassroomLesson?.start_date &&
          toLocalDate(responseClassroomLesson?.start_date);
        const newModifiedDate = oldHomeworkStartDate?.split(" ");
        newModifiedDate && setStartDate(newModifiedDate[0]);
        newModifiedDate && setStartTime(newModifiedDate[1]);
        const finalTeacherClassrooms =
          TeacherClassroooms.getTeacherClassrooms()?.find((classroom) => {
            return (
              Number(classroom?.id) === responseClassroomLesson?.classroom_id
            );
          });
        finalTeacherClassrooms && setClassRoom(finalTeacherClassrooms);
      }
    })();
  }, [taskId, getClassroomLessonById, setClassRoom]);

  useEffect(() => {
    if (classroomLesson?.lessons && classroomLesson?.lessons.length > 0) {
      return;
    } else if (incomingSelectedLesson) {
      setClassroomLesson({
        ...classroomLesson,
        lesson: Number(incomingSelectedLesson?.id),
        start_date: `${startDate} ${startTime}`,
        classroom_id: incomingSelectedClassroom?.id
          ? Number(incomingSelectedClassroom?.id)
          : Number(classRoom?.id),
      });
      setSelectedLesson(incomingSelectedLesson);
    }
  }, [startDate, startTime]);

  useEffect(() => {
    let finalTeacherClassrooms =
      TeacherClassroooms.getTeacherClassrooms()?.filter(
        (classroom: getClassroomsByTeacher_getClassroomsByTeacher) => {
          return (
            Number(classroom?.program?.subject?.id) ===
            Number(incomingSelectedLesson?.subject?.id)
          );
        }
      );

    if (finalTeacherClassrooms && finalTeacherClassrooms.length === 1) {
      setClassRoom(finalTeacherClassrooms[0]);
    }
  }, []);

  return (
    <>
      <Drawer
        title={
          isMobile ? (
            <HeaderDrawerMobile
              onClose={onClose}
              goBack={onCloseDrawer}
              title={
                incomingSelectedClassroom
                  ? "Volver a la Clase"
                  : "Volver a la Lección"
              }
            />
          ) : (
            <p style={{ cursor: "pointer" }} onClick={onClose}>
              {incomingSelectedClassroom
                ? "Volver a la Clase"
                : "Volver a la Lección"}{" "}
            </p>
          )
        }
        height={isMobile ? "500px" : ""}
        placement={isMobile ? "bottom" : "right"}
        open={visible}
        closable={false}
        bodyStyle={{ background: "rgba(237, 237, 237, 0.8)" }}
        extra={
          isMobile ? (
            <></>
          ) : (
            <Space>
              <img
                src={cross}
                alt=""
                className={styles.crossImg}
                onClick={() => {
                  onCloseDrawer();
                }}
              />
            </Space>
          )
        }
        footer={
          <div className={styles.footer}>
            <Button
              disabled={!isFormComplete(classroomLesson)}
              className={styles.taskButton}
              style={
                isFormComplete(classroomLesson)
                  ? {
                      backgroundColor: "rgb(255, 188, 65)",
                      borderRadius: "11px",
                    }
                  : {
                      backgroundColor: "rgba(189, 185, 185, 0.64)",
                      borderRadius: "11px",
                    }
              }
              onClick={handleSubmit}
            >
              {taskId ? " Guardar" : "Planear videolección"}
            </Button>
          </div>
        }
        className={styles.drawerContainer}
      >
        <div className={styles.container}>
          <Collapse
            activeKey={[activeKey]}
            bordered
            ghost
            style={isMobile ? { width: "95%" } : {}}
          >
            {/* classrooms panel */}
            <Panel
              header={
                <div className={styles.panelTitle}>
                  Clase{" "}
                  <p className={styles.panelItemTitle}>
                    {classRoomPanelTitle()}
                  </p>{" "}
                </div>
              }
              extra={
                activeKey !== 1 &&
                finalTeacherClassrooms &&
                finalTeacherClassrooms.length >= 2 ? (
                  <button
                    onClick={() =>
                      activeKey === 1 ? setActiveKey(0) : setActiveKey(1)
                    }
                    className={styles.modifyTitle}
                  >
                    {classroomLesson.classroom_id ? "Modificar" : "Elegir"}
                  </button>
                ) : finalTeacherClassrooms &&
                  finalTeacherClassrooms.length >= 2 ? (
                  <button
                    onClick={() =>
                      activeKey === 1 ? setActiveKey(0) : setActiveKey(1)
                    }
                    className={styles.modifyTitleWhite}
                  >
                    Modificar
                  </button>
                ) : undefined
              }
              key={1}
              showArrow={false}
              className={styles.customPanelStyle}
            >
              {classRoomContent()}

              {CustomPanelFooter("classroom_id", Number(classRoom?.id))}
            </Panel>

            {/* lesson panel */}
            <Panel
              forceRender={true}
              header={
                <div className={styles.panelTitle}>
                  Lección{" "}
                  <p className={styles.panelItemTitle}>
                    {selectedLesson ? selectedLesson.title : "--"}
                  </p>{" "}
                </div>
              }
              key={2}
              showArrow={false}
              className={styles.customPanelStyle}
            ></Panel>

            {/* program panel */}
            <Panel
              header={
                <div className={styles.panelTitle}>
                  Programar
                  <p className={styles.panelItemTitle}>
                    {programPanelTitle(startDate, date)}
                  </p>
                </div>
              }
              extra={
                activeKey !== 3 ? (
                  <button
                    onClick={() =>
                      activeKey === 3 ? setActiveKey(0) : setActiveKey(3)
                    }
                    className={styles.modifyTitle}
                  >
                    Elegir
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      activeKey === 3 ? setActiveKey(0) : setActiveKey(3)
                    }
                    className={styles.modifyTitleWhite}
                  >
                    Modificar
                  </button>
                )
              }
              key={3}
              showArrow={false}
              className={styles.customPanelStyle}
            >
              <AssingDataClassroom
                setStartDate={setStartDate}
                setStartTime={setStartTime}
                startDate={startDate}
                startTime={startTime}
              />

              <div className={styles.classroomListSelectButtonContainer}>
                <div
                  className={styles.taskButton}
                  onClick={() => {
                    const clasroomLessonState = { ...classroomLesson };
                    const finalClassroomLessonState = {
                      ...clasroomLessonState,
                      start_date: `${startDate} ${startTime}`,
                    };
                    setClassroomLesson(finalClassroomLessonState);
                    setActiveKey(0);
                  }}
                >
                  Continuar
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Drawer>
    </>
  );
};
